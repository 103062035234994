import { Dokument } from './user';

export class AgreeJurPersonObj {
  JurDaten: {
    Personenart: String;
    Kundenart: String;
    Anrede: String;
    Briefanrede: String;
    // NamePraegekarte: String;
    // NamePraegekarte2: String;
    Firmenname: String;
    Kurzname: String;
    AnzMitarbeiter?: Number;
    StandAnzMitarbeiter?: String;
    Gruendungsland: String;
    Gruendungsort: String;
    Gruendungsdatum?: String;
    RechtsformAuspraegung: String;
    NiederlassungLand: String;
    GbrArt: String;
  };
  Vertragsdaten: {
    Stammvertrag: String;
    Stammvertragsdatum: String;
    Geschaeftsstelle: Number;
    NrBerater: Number;
    Kundensegment: Number;
  };
  Wirtschaftsdaten: {
    Wirtschaftszweig: String;
    WirtschaftszweigNumerisch: Number;
    WZAnteil: Number;
    SteuerID: String;
    GWGRisikoklasse: String;
  };
  Registerdaten: {
    Registerart: String;
    Registergericht: String;
    Registernummer: String;
    EintragDatum: String;
    RegAuszugDatum: String;
    RegGeprueftVon: String;
    RegGeprueftAm: String;
  };
  Adresse: {
    PLZ: String;
    Ort: String;
    Strasse: String;
    Land: String;
  };
  Kontaktdaten: {
    Kontakt: [
      {
        LaufendeNummer: number;
        Kontaktart: String;
        Kontaktmedium: String;
        Wert: String;
      },
      {
        LaufendeNummer: number;
        Kontaktart: String;
        Kontaktmedium: String;
        Wert: String;
      },
    ];
  };
  Dokumente: {
    Handelsregisterauszug: Dokument;
    Transparenzregister: Dokument;
    Gesellschafterliste: Dokument;
    Gesellschaftsvertrag: Dokument;
    AusweiskopieVertretungsberechtigten: Dokument;
    Gewerberegister: Dokument;
    Gesellschafterregister: Dokument;
    AusweiskopieWB: Dokument;
    Nachweis_bei_WB: Dokument;
  };
}

export class AgreeNatPersonObj {
  NatDaten: {
    Personenart: String;
    Kundenart: String;
    Anrede: String;
    Titel?: String;
    Vorname?: String;
    Nachname?: String;
    Briefanrede: String;
    Geburtsname?: String;
    Geburtsdatum?: String;
    Geburtsort?: String;
    Geburtsland: String;
    RechtsformAuspraegung: String;
  };
  Vertragsdaten: {
    Stammvertrag: String;
    NrBerater: number;
    Kundensegment: number;
    Geschaeftsstelle?;
  };
  Wirtschaftsdaten: {
    Wirtschaftszweig?: String;
    Berufe?: {
      Beruf: [
        {
          Berufsbezeichnung: String;
          Berufsgruppe: String;
          selbststaendig: boolean;
        },
      ];
    };
    SteuerID?: String;
    GWGRisikoklasse: String;
  };

  Legitimationsdaten: {
    Ausweisart: String;
    Ausweisnummer: String;
    AusstellerLand: String;
    AusstellerBehoerde: String;
    AusgestelltAm: String;
    GueltigBis: String;
    GeprueftAm: String;
    GeprueftVonID: String;
    AusfOrganisation: String;
    ausweiskopieVorh?: boolean;
    Staatsangehoerigkeit: String;
    sonstBez?: String;
  };
  Adresse: {
    PLZ: String;
    Ort: String;
    Strasse: String;
    Land: String;
  };

  Kontaktdaten: {
    Kontakt: [
      {
        LaufendeNummer: number;
        Kontaktart: String;
        Kontaktmedium: String;
        Wert: String;
      },
      {
        LaufendeNummer: number;
        Kontaktart: String;
        Kontaktmedium: String;
        Wert: String;
      },
    ];
  };
}

export class RoleCall {
  NehmerID: number;
  GeberID: number;
  RollenID?: string;
  Bemerkung?: string;
  Rollenbezeichnung: string;
  RollenCode: number;
}
