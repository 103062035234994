import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { NgxFileDropModule } from 'ngx-file-drop';
import { QuillModule } from 'ngx-quill';
import { environment } from '../environments/environment';

import { DatePipe, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, isDevMode, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LuxonDateAdapter, MAT_LUXON_DATE_ADAPTER_OPTIONS } from '@angular/material-luxon-adapter';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import * as Sentry from '@sentry/angular';
import { BackButtonDisableModule } from 'angular-disable-browser-back-button';
import { KeycloakAngularModule, KeycloakBearerInterceptor, KeycloakService } from 'keycloak-angular';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ErrorPageComponent } from '../app/pages/footer-navigation/error-page/error-page.component';
import { AdminComponent } from './admin/admin.component';
import { EmailsComponent } from './admin/children/emails/emails.component';
import { ImportuserComponent } from './admin/children/importuser/importuser.component';
import { TranslationAdminComponent } from './admin/children/translation-admin/translation-admin.component';
import { TranslationLogComponent } from './admin/children/translation-admin/translation-log/translation-log.component';
import { UserAdminComponent } from './admin/children/user-admin/user-admin.component';
import { QuestionAnswerComponent } from './admin/children/user-detail-admin/question-answer/question-answer.component';
import { UserDetailAdminComponent } from './admin/children/user-detail-admin/user-detail-admin.component';
import { DialogPopUpComponent } from './admin/dialog-pop-up/dialog-pop-up.component';
import { MantinancePopUpComponent } from './admin/mantinance-pop-up/mantinance-pop-up.component';
import { TextModalPopUpComponent } from './admin/text-modal-pop-up/text-modal-pop-up.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AngebotAbhlenenDialogBoxComponent } from './component/dialog-boxes/angebot-abhlenen-dialog-box/angebot-abhlenen-dialog-box.component';
import { CreditCheckDialogComponent } from './component/dialog-boxes/credit-check-dialog/credit-check-dialog.component';
import { DefaultDialogBoxComponent } from './component/dialog-boxes/default-dialog-box/default-dialog-box.component';
import { DownloadDialogBoxComponent } from './component/dialog-boxes/download-dialog-box/download-dialog-box.component';
import { PepDialogBoxComponent } from './component/dialog-boxes/pep-dialog-box/pep-dialog-box.component';
import { InfoCardComponent } from './component/info-card/info-card.component';
import { LoaderComponent } from './component/loader/loader.component';
import { PdfViewBoxComponent } from './component/pdf-view-box/pdf-view-box.component';
import { TranslateModalComponent } from './component/translata-modal/translate-modal.component';
import { TranslationDirective } from './component/translata-modal/translation.directive';
import { DatenschutzComponent } from './pages/footer-navigation/datenschutz/datenschutz.component';
import { DisclaimerComponent } from './pages/footer-navigation/disclaimer/disclaimer.component';
import { FooterComponent } from './pages/footer-navigation/footer/footer.component';
import { ImpressumComponent } from './pages/footer-navigation/impressum/impressum.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { QuestionnaireComponent } from './pages/questionnaire/questionnaire.component';
import { BlankoDokumenteComponent } from './pages/questionnaire/questions/blanko-dokumente/blanko-dokumente.component';
import { DepotbankWahlComponent } from './pages/questionnaire/questions/depotbank-wahl/depotbank-wahl.component';
import { DokumenteComponent } from './pages/questionnaire/questions/dokumente/dokumente.component';
import { BankacountsComponent } from './pages/questionnaire/questions/einsicht/bankacounts/bankacounts.component';
import { EinsichtComponent } from './pages/questionnaire/questions/einsicht/einsicht.component';
import { KreditcheckabschlussComponent } from './pages/questionnaire/questions/einsicht/kreditcheckabschluss/kreditcheckabschluss.component';
import { IdentifizierungComponent } from './pages/questionnaire/questions/identifizierung/identifizierung.component';
import { OverviewComponent } from './pages/questionnaire/questions/overview/overview.component';
import { ZusammenfassungComponent } from './pages/questionnaire/questions/zusammenfassung/zusammenfassung.component';
import { ServerErrorInterceptor } from './services/server-error.interceptor';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, `${environment.apiURL}/lang/`, '');
}

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD.MM.YYYY',
    monthYearA11yLabel: 'MMM YYYY',
  },
};

function initializeKeycloak(keycloak: KeycloakService) {
  return () =>
    keycloak.init({
      config: environment.mccKeycloakConfig,
      initOptions: {
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html',
      },
      enableBearerInterceptor: true,
      bearerPrefix: 'Bearer',
      bearerExcludedUrls: [
        // environment.apiURL + "",
        'https://bankhausbauer.kontowechselservice.de/INTE/SSO.aspx',
      ],
    });
}

@NgModule({
  declarations: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
  imports: [
    BackButtonDisableModule.forRoot(),
    KeycloakAngularModule,
    PdfViewerModule,
    BrowserModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    Ng2GoogleChartsModule,
    QuillModule.forRoot(),
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    NgxFileDropModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatButtonModule,
    MatToolbarModule,
    MatProgressBarModule,
    MatCardModule,
    MatListModule,
    MatDividerModule,
    MatIconModule,
    MatSnackBarModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatTabsModule,
    MatTableModule,
    MatRadioModule,
    MatMenuModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatSortModule,
    MatSidenavModule,
    MatStepperModule,
    MatExpansionModule,
    MatTooltipModule,
    QuestionnaireComponent,
    AdminComponent,
    PageNotFoundComponent,
    OverviewComponent,
    TranslateModalComponent,
    TranslationDirective,
    ZusammenfassungComponent,
    BlankoDokumenteComponent,
    EinsichtComponent,
    IdentifizierungComponent,
    TranslationAdminComponent,
    TranslationLogComponent,
    UserAdminComponent,
    UserDetailAdminComponent,
    QuestionAnswerComponent,
    EmailsComponent,
    DialogPopUpComponent,
    MantinancePopUpComponent,
    DepotbankWahlComponent,
    TextModalPopUpComponent,
    PdfViewBoxComponent,
    InfoCardComponent,
    ImportuserComponent,
    PepDialogBoxComponent,
    BankacountsComponent,
    KreditcheckabschlussComponent,
    ImpressumComponent,
    DatenschutzComponent,
    DisclaimerComponent,
    FooterComponent,
    LoaderComponent,
    AngebotAbhlenenDialogBoxComponent,
    ErrorPageComponent,
    DownloadDialogBoxComponent,
    DefaultDialogBoxComponent,
    DokumenteComponent,
    CreditCheckDialogComponent,
  ],
  providers: [
    ...(environment.name === 'dev' || isDevMode()
      ? [
          {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
              showDialog: false,
            }),
          },
          {
            provide: Sentry.TraceService,
            deps: [Router],
          },
          {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true,
          },
        ]
      : []),
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService],
    },
    DatePipe,
    { provide: LOCALE_ID, useValue: 'de' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ServerErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: KeycloakBearerInterceptor,
      multi: true,
    },
    { provide: MAT_DATE_LOCALE, useValue: 'de' },
    { provide: MAT_LUXON_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    {
      provide: DateAdapter,
      useClass: LuxonDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_LUXON_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: MatDialogRef, useValue: undefined },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {
  constructor() {
    registerLocaleData(localeDe);
  }
}
