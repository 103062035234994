"use strict";

/* istanbul ignore file */
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.InfoParam = void 0;
/**
 * @internal
 */
var InfoParam;
(function (InfoParam) {
  InfoParam["REDIRECT_COMPLETED"] = "redirect.completed";
})(InfoParam = exports.InfoParam || (exports.InfoParam = {}));
