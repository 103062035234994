import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { MatToolbar, MatToolbarRow } from '@angular/material/toolbar';
import { RouterLink, RouterOutlet, RouterLinkActive } from '@angular/router';
import { TranslationDirective } from '../../../component/translata-modal/translation.directive';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    standalone: true,
    imports: [
        MatToolbar,
        MatToolbarRow,
        RouterLink,
        RouterOutlet,
        RouterLinkActive,
        TranslationDirective,
        TranslateModule,
        NgIf,
    ],
})
export class FooterComponent implements OnInit {
  logoImagePath;
  logoImage;
  constructor(public authService: AuthService) {}

  ngOnInit(): void {
    let logoImagePathOD = 'assets/img/menu-logo-odfin.svg';
    let logoImagePathCC = 'assets/img/menu-logo-carecapital.svg';
    let logoImageOD = 'assets/img/Bankhaus_Bauer_Logo.svg';
    let logoImageMCC = 'assets/img/Bankhaus_Bauer_Logo1.svg';
    this.authService.checkLogin().subscribe(() => {
      if (this.authService.user.lang === 'mcc') {
        this.logoImagePath = logoImagePathCC;
        this.logoImage = logoImageMCC;
      } else {
        this.logoImagePath = logoImagePathOD;
        this.logoImage = logoImageOD;
      }
    });
  }
}
