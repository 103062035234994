<div class="dialogBox" [ngClass]="{ 'optadata-theme': imagelang === 'odfin' }">
  <div *ngIf="!pepConfirm">
    <h3 appTranslationModal [translate]="'RECHTLICHES.POPUP_TITLE'"></h3>
    <div style="display: flex; justify-content: space-evenly">
      <button mat-stroked-button color="accent" [mat-dialog-close]="false">nein</button>
      <button mat-stroked-button color="accent" (click)="changeBox()">ja</button>
    </div>
  </div>
  <div *ngIf="pepConfirm">
    <h3 appTranslationModal [translate]="'RECHTLICHES.POPUP_END'"></h3>
    <div style="display: flex; justify-content: space-evenly">
      <button mat-stroked-button color="accent" [mat-dialog-close]="true">Schließen</button>
    </div>
  </div>
</div>
