import { AuthService } from 'src/app/services/auth.service';
import { QuestionService } from 'src/app/services/question.service';

import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { QuestionTemplate } from '../question-template';
import { NgIf } from '@angular/common';
import { TranslationDirective } from '../../../../component/translata-modal/translation.directive';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-overview',
    templateUrl: './overview.component.html',
    styleUrls: ['./overview.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        TranslationDirective,
        TranslateModule,
    ],
})
export class OverviewComponent extends QuestionTemplate {
  welcomeName: string;
  headerImagePath: string;
  logoImagePath: string;
  constructor(auth: AuthService, questionService: QuestionService, route: ActivatedRoute) {
    const url = route.snapshot.url;
    let path = '';
    if (url.length > 0) path = url[0].path;
    super(auth, questionService, path);
    this.questionName = 'overview';
    let index = this.auth.findFlaggedNaturalPerson(this.auth.user.natPersonen);
    let title = this.auth.user.natPersonen[index].Person.Titel;
    let vorname = this.auth.user.natPersonen[index].Person.Vorname;
    let nachname = this.auth.user.natPersonen[index].Person.Nachname;
    let anrede = this.auth.user.natPersonen[index].Person.Anrede;
    this.headerImagePath = 'assets/img/overview-mainImage-mobile';
    const logoImagePathOD = 'assets/img/menu-logo-odfin.svg';
    const logoImagePathCC = 'assets/img/menu-logo-carecapital.svg';
    const { lang, natPersonen } = this.auth.user;
    this.logoImagePath = lang === 'mcc' ? logoImagePathCC : logoImagePathOD;
    const { Fachgebiet } = natPersonen[index].BranchenInfo;

    const suffix =
      lang === 'mcc' ? (Fachgebiet === 'Zahnarztpraxen' ? '-zahnarzt.png' : '-carecapital.png') : '-odfin.png';

    this.headerImagePath += suffix;

    //let liebe = anrede == "Herr" ? "Lieber" : "Liebe";
    this.welcomeName = 'Hallo ' + anrede + ' ' + title + ' ' + vorname + ' ' + nachname;

    window.localStorage.clear();
  }
}
