import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { RechtsformMap, land3alpha } from '../types/mappingEvaDifa';
import { CreditCheckType } from '../models/finapi';

export class CreditCheckobj {
  personalData: [
    {
      gender: String;
      firstName: String;
      lastName: String;
      dateOfBirth: String;
      currentAddress: {
        street: String;
        zip: String;
        city: String;
      };
    },
  ];
  companyData: {
    companyName: String;
    crefoIdentificationNumber: String;
    currentAddress: {
      street: String;
      zip: String;
      city: String;
      country: String | undefined;
    };
  };
  accounts?: [];
}

@Injectable({
  providedIn: 'root',
})
export class FinapiService {
  PROCESS_TOKEN: String;
  ACCESS_TOKEN: String;
  IMPORT_ID: String;
  selectedAccountId: any[];
  creditCheckobj = new CreditCheckobj();
  creditCheckType: String;

  constructor(
    private auth: AuthService,
    private http: HttpClient
  ) {}

  public getWebForm(lang): Observable<any> {
    return this.http.post(`${environment.apiURL}/finapi/webFormUrl`, {
      lang,
      userId: this.auth.user._id,
    });
  }

  public getAccounts(processToken, accessToken, importId): Observable<any> {
    return this.http.post(`${environment.apiURL}/finapi/accounts`, {
      processToken,
      accessToken,
      importId,
    });
  }

  public getWebFormStatus(accessToken, webFormId): Observable<any> {
    return this.http.post(`${environment.apiURL}/finapi/getWebForm`, {
      accessToken,
      webFormId,
      userId: this.auth.user._id,
    });
  }

  public checkIdentity(processToken, accessToken): Observable<any> {
    return this.http.post(`${environment.apiURL}/finapi/checkIdentity`, {
      processToken,
      accessToken,
      userId: this.auth.user._id,
    });
  }

  public creditCheck(processToken, accessToken, requestCreditCard: boolean, creditCheckType): Observable<any> {
    // hint to backend to clear(empty) company-Registerdaten if we are a GBR when calling finapi
    let isGBR = false;
    if (this.auth.user.JurPerson?.JurDaten?.GbrArt === 'GbR ohne Haftungsbeschraenkung') {
      isGBR = true;
    }
    return this.http.post(`${environment.apiURL}/finapi/creditCheck`, {
      _id: this.auth.user._id,
      processToken,
      accessToken,
      requestCreditCard,
      creditCheckType,
      creditCheckobj: this.creditCheckobj,
      isGBR,
    });
  }

  public creditCheckWithoutWebForm(requestCreditCard: boolean, creditCheckType): Observable<any> {
    // hint to backend to clear(empty) company-Registerdaten if we are a GBR when calling finapi
    let isGBR = false;
    if (this.auth.user.JurPerson?.JurDaten?.GbrArt === 'GbR ohne Haftungsbeschraenkung') {
      isGBR = true;
    }
    return this.http.post(`${environment.apiURL}/finapi/creditCheckWithoutWebForm`, {
      _id: this.auth.user._id,
      requestCreditCard,
      creditCheckType,
      creditCheckobj: this.creditCheckobj,
      isGBR,
    });
  }

  public getProccessToken(): Observable<any> {
    return this.http.post(`${environment.apiURL}/finapi/getProccessToken`, {
      _id: this.auth.user._id,
    });
  }

  public getAccessToken(processToken): Observable<any> {
    return this.http.post(`${environment.apiURL}/finapi/getAccessToken`, {
      _id: this.auth.user._id,
      processToken: processToken,
    });
  }

  public transactions(accessToken, page): Observable<any> {
    return this.http.post(`${environment.apiURL}/finapi/transactions`, {
      _id: this.auth.user._id,
      accessToken,
      selectedAccountId: this.selectedAccountId,
      page,
    });
  }

  public finalizeProcess(processToken, accessToken): Observable<any> {
    return this.http.post(`${environment.apiURL}/finapi/finalize`, {
      processToken,
      accessToken,
    });
  }

  public postSelectedAccountList(selectedAccountList): Observable<any> {
    return this.http.post(`${environment.apiURL}/finapi/selectedAccountList`, {
      _id: this.auth.user._id,
      selectedAccountList: selectedAccountList,
    });
  }

  public async buildCreditCheckobj() {
    const userAnswer = this.auth.user.questions[0]?.answer;
    const natPerson = this.auth.user.natPersonen[this.auth.index];
    const jurPerson = this.auth.user.JurPerson;
    const isJurPerson = this.auth.isJurPerson();
    const anrede = natPerson.Person.Anrede;
    const gender = anrede === 'Herr' ? 'M' : anrede === 'Frau' ? 'F' : 'U';
    const kreditKarte = userAnswer?.kreditKarte;
    const kontokorrentkredit = userAnswer?.kontokorrentkredit;
    const kontoWechselService = userAnswer?.kontoWechselService;

    if (kreditKarte && kontokorrentkredit && kontoWechselService) {
      this.creditCheckType = CreditCheckType.full;
    } else if (kreditKarte && kontokorrentkredit) {
      this.creditCheckType = CreditCheckType.full;
    } else if (kontokorrentkredit && kontoWechselService) {
      this.creditCheckType = CreditCheckType.full;
    } else if (kreditKarte && kontoWechselService) {
      this.creditCheckType = CreditCheckType.full;
    } else if (kreditKarte) {
      this.creditCheckType = CreditCheckType.full;
    } else if (kontokorrentkredit) {
      this.creditCheckType = CreditCheckType.full;
    } else if (kontoWechselService) {
      this.creditCheckType = CreditCheckType.simple;
    } else {
      this.creditCheckType = CreditCheckType.simple;
    }

    const abweichendeCurrentAddress: boolean = natPerson.Firmenanschrift.Strasse ? true : false;

    let currentAddress;
    isJurPerson
      ? (currentAddress = {
          street: jurPerson.Adresse.Strasse,
          zip: jurPerson.Adresse.PLZ,
          city: jurPerson.Adresse.Ort,
          country: land3alpha.get(jurPerson.Adresse.Land),
        })
      : (currentAddress = {
          street: abweichendeCurrentAddress ? natPerson.Firmenanschrift.Strasse : natPerson.Adresse.Strasse,
          zip: abweichendeCurrentAddress ? natPerson.Firmenanschrift.PLZ : natPerson.Adresse.PLZ,
          city: abweichendeCurrentAddress ? natPerson.Firmenanschrift.Ort : natPerson.Adresse.Ort,
          country: abweichendeCurrentAddress
            ? land3alpha.get(natPerson.Firmenanschrift.Land)
            : land3alpha.get(natPerson.Adresse.Land),
        });

    const personalData = {
      gender,
      firstName: natPerson.Person.Vorname,
      lastName: natPerson.Person.Nachname,
      dateOfBirth: natPerson.Person.Geburtsdatum,
      currentAddress: {
        street: natPerson.Adresse.Strasse,
        zip: natPerson.Adresse.PLZ,
        city: natPerson.Adresse.Ort,
      },
    };

    const companyName = isJurPerson ? jurPerson?.JurDaten.Firmenname : natPerson.Firmenname.substring(0, 35);
    const crefoIdentificationNumber = natPerson.CrefoID;

    var rechtsform;

    if (this.auth.user.isJurPerson) {
      const RechtsformAuspraegung = jurPerson?.JurDaten.RechtsformAuspraegung.trim();

      if (RechtsformMap.has(RechtsformAuspraegung)) {
        rechtsform = RechtsformMap.get(RechtsformAuspraegung);
      }
    }

    const companyData = {
      companyType: isJurPerson ? rechtsform : 'FREIBERUFLER',
      companyName,
      crefoIdentificationNumber,
      currentAddress,
      companyLegalForm: isJurPerson ? rechtsform : 'FREIBERUFLER',
      companyRegisterType: isJurPerson ? (jurPerson.Registerdaten ? jurPerson.Registerdaten.Registerart : '') : '',
      companyRegisterId: isJurPerson ? (jurPerson.Registerdaten ? jurPerson.Registerdaten.Registernummer : '') : '',

      companyVATId: isJurPerson ? '' : '',
    };

    const accounts: any =
      this.selectedAccountId.length === 1
        ? [
            {
              accountId: this.selectedAccountId[0],
            },
          ]
        : [
            {
              accountId: this.selectedAccountId[0],
            },
            {
              accountId: this.selectedAccountId[1],
            },
          ];

    this.creditCheckobj = {
      personalData: [personalData],
      companyData,
      accounts,
    };
  }

  public async buildCreditCheckobjSimple() {
    const natPerson = this.auth.user.natPersonen[this.auth.index];
    const jurPerson = this.auth.user.JurPerson;
    const isJurPerson = this.auth.isJurPerson();
    const anrede = natPerson.Person.Anrede;
    const gender = anrede === 'Herr' ? 'M' : anrede === 'Frau' ? 'F' : 'U';
    const userAnswer = this.auth.user.questions[0]?.answer;
    const kreditKarte = userAnswer?.kreditKarte;
    const kontokorrentkredit = userAnswer?.kontokorrentkredit;
    const kontoWechselService = userAnswer?.kontoWechselService;

    if (kreditKarte && kontokorrentkredit && kontoWechselService) {
      this.creditCheckType = CreditCheckType.full;
    } else if (kreditKarte && kontokorrentkredit) {
      this.creditCheckType = CreditCheckType.full;
    } else if (kontokorrentkredit && kontoWechselService) {
      this.creditCheckType = CreditCheckType.full;
    } else if (kreditKarte && kontoWechselService) {
      this.creditCheckType = CreditCheckType.full;
    } else if (kreditKarte) {
      this.creditCheckType = CreditCheckType.full;
    } else if (kontokorrentkredit) {
      this.creditCheckType = CreditCheckType.full;
    } else if (kontoWechselService) {
      this.creditCheckType = CreditCheckType.simple;
    } else {
      this.creditCheckType = CreditCheckType.simple;
    }

    const abweichendeCurrentAddress: boolean = natPerson.Firmenanschrift.Strasse ? true : false;

    let currentAddress;
    isJurPerson
      ? (currentAddress = {
          street: jurPerson.Adresse.Strasse,
          zip: jurPerson.Adresse.PLZ,
          city: jurPerson.Adresse.Ort,
          country: land3alpha.get(jurPerson.Adresse.Land),
        })
      : (currentAddress = {
          street: abweichendeCurrentAddress ? natPerson.Firmenanschrift.Strasse : natPerson.Adresse.Strasse,
          zip: abweichendeCurrentAddress ? natPerson.Firmenanschrift.PLZ : natPerson.Adresse.PLZ,
          city: abweichendeCurrentAddress ? natPerson.Firmenanschrift.Ort : natPerson.Adresse.Ort,
          country: abweichendeCurrentAddress
            ? land3alpha.get(natPerson.Firmenanschrift.Land)
            : land3alpha.get(natPerson.Adresse.Land),
        });

    const personalData = {
      gender,
      firstName: natPerson.Person.Vorname,
      lastName: natPerson.Person.Nachname,
      dateOfBirth: natPerson.Person.Geburtsdatum,
      currentAddress: {
        street: natPerson.Adresse.Strasse,
        zip: natPerson.Adresse.PLZ,
        city: natPerson.Adresse.Ort,
      },
    };

    const companyName = isJurPerson ? jurPerson?.JurDaten.Firmenname : natPerson.Firmenname.substring(0, 35);
    const crefoIdentificationNumber = natPerson.CrefoID;

    var rechtsform;

    if (this.auth.user.isJurPerson) {
      const RechtsformAuspraegung = jurPerson?.JurDaten.RechtsformAuspraegung.trim();

      if (RechtsformMap.has(RechtsformAuspraegung)) {
        rechtsform = RechtsformMap.get(RechtsformAuspraegung);
      }
    }

    const companyData = {
      companyType: isJurPerson ? rechtsform : 'FREIBERUFLER',
      companyName,
      crefoIdentificationNumber,
      currentAddress,
      companyLegalForm: isJurPerson ? rechtsform : 'FREIBERUFLER',
      companyRegisterType: isJurPerson ? (jurPerson.Registerdaten ? jurPerson.Registerdaten.Registerart : '') : '',
      companyRegisterId: isJurPerson ? (jurPerson.Registerdaten ? jurPerson.Registerdaten.Registernummer : '') : '',
      companyVATId: isJurPerson ? '' : '',
    };

    this.creditCheckobj = {
      personalData: [personalData],
      companyData,
    };
  }
}
