<div class="download-dialog">
  <div class="text-center" style="padding: 3rem" *ngIf="isDownloading">
    <app-loader class="spinner"></app-loader>
    <h3 appTranslationModal [translate]="'DOKUMENTESEITE.POPUP_DOWNLOAD_TEXT'"></h3>
    <p appTranslationModal [translate]="'DOKUMENTESEITE.POPUP_DOWNLOAD_SUBTEXT'"></p>
  </div>
  <div class="text-center success-container" style="padding: 3rem" *ngIf="!isDownloading">
    <mat-icon class="check-icon success-icon">check</mat-icon>
    <h3 appTranslationModal [translate]="'DOKUMENTESEITE.POPUP_END_DOWNLOAD_TEXT'"></h3>
  </div>
</div>
