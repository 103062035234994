<div class="row g-0">
  <!-- header -->
  <div class="col-12 header" *ngIf="showHeader">
    <mat-toolbar class="content-card">
      <mat-toolbar-row style="justify-content: space-between">
        <div class="header-left">
          <img class="logo-nav" src="{{ logoImagePath }}" alt="logo" />
        </div>
        <div class="header-right">
          <!-- <div class="time-info">
            <p class="time-text">
              Zeit bis zum Logout: <span id="time-left-display">{{ timeLeft }}</span>
            </p>

            <button mat-icon-button [matTooltip]="'Sitzung verlängern'" (click)="refreshToken()" class="refresh-button">
              <mat-icon>refresh</mat-icon>
            </button>
          </div> -->
          <div class="user-info">
            <p>{{ user.name }} {{ user.lastname }}</p>
            <mat-menu #appMenu="matMenu">
              <button mat-menu-item routerLink="/admin" *ngIf="isAdminOrConsultant">
                <span translate="Admin"></span>
              </button>
              <button mat-menu-item (click)="logout()">
                <span translate="Logout"></span>
              </button>
            </mat-menu>
            <button mat-icon-button [matMenuTriggerFor]="appMenu" class="menu-button">
              <mat-icon>more_vert</mat-icon>
            </button>
          </div>
        </div>
      </mat-toolbar-row>
    </mat-toolbar>
  </div>

  <!--Main Content-->
  <div class="col-12 content-card padding-x content-card-main" [ngClass]="{ 'optadata-theme': imagelang === 'odfin' }">
    <div class="row g-0">
      <!--stepper-->
      <div class="stepper" *ngIf="stepPage">
        <div id="stepper-header" class="col-12 d-none d-md-block">
          <mat-horizontal-stepper #stepper class="pb-4" [selectedIndex]="stepNumber">
            <ng-template matStepperIcon="edit">
              <mat-icon>check</mat-icon>
            </ng-template>
            <mat-step [label]="step.label" #myStep *ngFor="let step of steps"> </mat-step>
          </mat-horizontal-stepper>
        </div>
        <div id="stepper-header-mobile" class="col-12 d-md-none">
          <mat-horizontal-stepper hide-header #stepper class="pb-4" [selectedIndex]="stepNumber">
            <ng-template matStepperIcon="edit">
              <mat-icon>check</mat-icon>
            </ng-template>
            <mat-step #myStep *ngFor="let step of steps"> </mat-step>
          </mat-horizontal-stepper>
        </div>
      </div>
    </div>
    <div class="row g-0 col-12 content-container justify-content-center">
      <!--Content left: image or description-->
      <div class="col-xl-5 d-none d-xl-block" *ngIf="!pathIsEinsicht">
        <img class="card-img" matCardImage src="{{ descriptionImagePath }}" *ngIf="showImage" />

        <div class="description-container" *ngIf="!showImage">
          <h2 [appTranslationModal]="description.title" [innerHTML]="description.title | translate"></h2>
          <p [appTranslationModal]="description.text" [innerHTML]="description.text | translate"></p>
        </div>
      </div>

      <!--Content right: Form from the router-->
      <div
        class="col-12"
        [ngClass]="{
          'col-xl-12': pathIsEinsicht,
          'col-xl-7': showHeader && showImage,
          'col-xl-5': !showHeader || !showImage,
        }"
      >
        <!-- Centered Spinner Container -->
        <mat-progress-spinner
          style="position: absolute; top: 160rem"
          *ngIf="isLoading"
          mode="indeterminate"
        ></mat-progress-spinner>
        <router-outlet></router-outlet>

        <div class="col-12" style="text-align: center" *ngIf="!showHeader">
          <button
            mat-raised-button
            (click)="nextStep()"
            color="primary"
            [disabled]="answerFormGroup && !answerFormGroup.valid"
          >
            <span style="color: white" appTranslationModal [translate]="'WILLKOMMEN.BUTTON_TEXT'"></span>
          </button>
        </div>
        <div class="col-9" style="padding: 2.5rem" *ngIf="!showHeader">
          <p appTranslationModal [translate]="'WILLKOMMEN.BOTTOM_TEXT'"></p>
        </div>
      </div>
    </div>

    <!--Nav Buttons-->
    <div class="col-12" style="display: flex; justify-content: center" *ngIf="stepPage">
      <div
        class="col-12 order-1 order-lg-2"
        [ngClass]="{
          'col-lg-7': showHeader && showImage,
          'col-lg-4 ': !showHeader || !showImage,
        }"
      >
        <div
          class="btn-next pb-4"
          *ngIf="nextButtonTitle !== 'Jetzt Geschäftskonto eröffnen' || answerFormGroup?.valid"
        >
          <button
            mat-raised-button
            (click)="handleSaveAndDisableButton()"
            class="next-step"
            color="primary"
            [disabled]="isButtonDisabled() || isSaveAndContinueDisabled"
          >
            {{ nextButtonTitle }}
            <mat-icon style="font-size: 1.3em" *ngIf="showIconInButton">arrow_forward_ios</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- footer -->
  <div class="col-12">
    <div class="footer content-card padding-x">
      <div class="row g-0 px-3">
        <div class="footer-element-container text-center col-12 col-xl-1 order-1 order-xl-2">
          <a class="footer-link" href="/info/impressum" target="_blank">
            <span appTranslationModal [translate]="'ALLGEMEIN.FOOTER_LINK1'"></span>
          </a>
        </div>
        <div class="footer-element-container text-center col-12 col-xl-1 order-2 order-xl-3">
          <a class="footer-link" href="/info/datenschutz" target="_blank">
            <span appTranslationModal [translate]="'ALLGEMEIN.FOOTER_LINK2'"></span>
          </a>
        </div>
        <div class="footer-element-container text-center col-12 col-xl-1 order-3 order-xl-4">
          <a class="footer-link" href="/info/disclaimer" target="_blank">
            <span appTranslationModal [translate]="'ALLGEMEIN.FOOTER_LINK3'"></span>
          </a>
        </div>
        <div *ngIf="imagelang === 'mcc'" class="BB-logo-container text-center col-12 col-xl-8 order-4 order-xl-5">
          <a href="https://bankhausbauer.de/" target="_blank">
            <img class="optadata-logo" src="{{ logoImage }}" alt="logo" />
          </a>
        </div>
        <div *ngIf="imagelang === 'odfin'" class="hidden text-center col-12 col-xl-8 order-4 order-xl-5">
          <a href="https://bankhausbauer.de/" target="_blank">
            <img class="optadata-logo" src="{{ logoImage }}" alt="logo" />
          </a>
        </div>
        <div class="footer-element-container text-center col-12 col-xl-1 order-5 order-xl-5">
          <img class="one-logo" src="assets/img/one-logo.svg" alt="logo" />
        </div>
      </div>

      <p style="font-size: 9px; margin-left: 2rem" appTranslationModal [translate]="'ALLGEMEIN.FOOTER_TEXT'"></p>
    </div>
  </div>
</div>
