<div class="ablehnen-box" [ngClass]="{ 'optadata-theme': imagelang === 'odfin' }">
  <h3 appTranslationModal [translate]="'FINAPI.ABLEHNEN_POPUP_TEXT'"></h3>
  <p appTranslationModal [translate]="'FINAPI.ABLEHNEN_POPUP_SUBTEXT'"></p>
  <div class="textarea-Container">
    <textarea cols="60" rows="7" placeholder="Ihren Feedback"></textarea>
  </div>
  <div style="display: flex; justify-content: space-evenly">
    <button mat-stroked-button color="accent" [mat-dialog-close]="false">zurück</button>
    <button mat-stroked-button color="accent" [mat-dialog-close]="true">senden</button>
  </div>
</div>
