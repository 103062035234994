<div class="p-5" *ngIf="langIsMcc">
  <h1>Datenschutzerklärung</h1>
  <h2>Datenschutz</h2>
  <p>
    Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre
    personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser
    Datenschutzerklärung.
  </p>
  <p>
    Die Nutzung unserer Webseite ist in der Regel ohne Angabe personenbezogener Daten möglich. Soweit auf unseren Seiten
    personenbezogene Daten (beispielsweise Name, Anschrift oder E-Mail-Adressen) erhoben werden, erfolgt dies, soweit
    möglich, stets auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte
    weitergegeben.
  </p>
  <p>
    Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail)
    Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.
  </p>
  <p>&nbsp;</p>
  <h2>Cookies</h2>
  <p>
    Die Internetseiten verwenden teilweise so genannte Cookies. Cookies richten auf Ihrem Rechner keinen Schaden an und
    enthalten keine Viren. Cookies dienen dazu, unser Angebot nutzerfreundlicher, effektiver und sicherer zu machen.
    Cookies sind kleine Textdateien, die auf Ihrem Rechner abgelegt werden und die Ihr Browser speichert.
  </p>
  <p>
    Die meisten der von uns verwendeten Cookies sind so genannte „Session-Cookies“. Sie werden nach Ende Ihres Besuchs
    automatisch gelöscht. Andere Cookies bleiben auf Ihrem Endgerät gespeichert, bis Sie diese löschen. Diese Cookies
    ermöglichen es uns, Ihren Browser beim nächsten Besuch wiederzuerkennen.
  </p>
  <p>
    Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und Cookies nur im
    Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell ausschließen, sowie das automatische
    Löschen der Cookies beim Schließen des Browser aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität
    dieser Website eingeschränkt sein.
  </p>
  <p>&nbsp;</p>
  <h2>Server-Log-Files</h2>
  <p>
    Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-Log Files, die Ihr
    Browser automatisch an uns übermittelt. Dies sind:
  </p>
  <ul>
    <li>Browsertyp und Browserversion</li>
    <li>verwendetes Betriebssystem</li>
    <li>Referrer URL</li>
    <li>Hostname des zugreifenden Rechners</li>
    <li>Uhrzeit der Serveranfrage</li>
  </ul>
  <p>
    Diese Daten sind nicht bestimmten Personen zuordenbar. Eine Zusammenführung dieser Daten mit anderen Datenquellen
    wird nicht vorgenommen. Wir behalten uns vor, diese Daten nachträglich zu prüfen, wenn uns konkrete Anhaltspunkte
    für eine rechtswidrige Nutzung bekannt werden.
  </p>
  <p>&nbsp;</p>
  <h2>Kontaktformular</h2>
  <p>
    Wenn Sie uns per Kontaktformular Anfragen zukommen lassen, werden Ihre Angaben aus dem Anfrageformular inklusive der
    von Ihnen dort angegebenen Kontaktdaten zwecks Bearbeitung der Anfrage und für den Fall von Anschlussfragen bei uns
    gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.
  </p>
  <p>&nbsp;</p>
  <h2>Newsletterdaten</h2>
  <p>
    Wenn Sie den auf der Webseite angebotenen Newsletter beziehen möchten, benötigen wir von Ihnen eine E-Mail-Adresse
    sowie Informationen, welche uns die Überprüfung gestatten, dass Sie der Inhaber der angegebenen E-Mail-Adresse sind
    und mit dem Empfang des Newsletters einverstanden sind. Weitere Daten werden nicht erhoben. Diese Daten verwenden
    wir ausschließlich für den Versand der angeforderten Informationen und geben sie nicht an Dritte weiter.
  </p>
  <p>
    Die erteilte Einwilligung zur Speicherung der Daten, der E-Mail-Adresse sowie deren Nutzung zum Versand des
    Newsletters können Sie jederzeit widerrufen, etwa über den "Austragen"-Link im Newsletter.
  </p>
  <p>&nbsp;</p>
  <h2>Google Analytics</h2>
  <p>
    Diese Website nutzt Funktionen des Webanalysedienstes Google Analytics. Anbieter ist die Google Inc., 1600
    Amphitheatre Parkway Mountain View, CA 94043, USA.
  </p>
  <p>
    Google Analytics verwendet so genannte "Cookies". Das sind Textdateien, die auf Ihrem Computer gespeichert werden
    und die eine Analyse der Benutzung der Website durch Sie ermöglichen. Die durch den Cookie erzeugten Informationen
    über Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in den USA übertragen und dort
    gespeichert.
  </p>
  <p>
    Mehr Informationen zum Umgang mit Nutzerdaten bei Google Analytics finden Sie in der Datenschutzerklärung von
    Google:
    <a href="https://support.google.com/analytics/answer/6004245?hl=de"
      >https://support.google.com/analytics/answer/6004245?hl=de</a
    >
  </p>
  <p><strong>Browser Plugin</strong></p>
  <p>
    Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir
    weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website
    vollumfänglich werden nutzen können. Sie können darüber hinaus die Erfassung der durch den Cookie erzeugten und auf
    Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten
    durch Google verhindern, indem Sie das unter dem folgenden Link verfügbare Browser-Plugin herunterladen und
    installieren:
    <a href="https://tools.google.com/dlpage/gaoptout?hl=de">https://tools.google.com/dlpage/gaoptout?hl=de</a>
  </p>
  <p><strong>Widerspruch gegen Datenerfassung</strong></p>
  <p>
    Sie können die Erfassung Ihrer Daten durch Google Analytics verhindern, indem Sie auf folgenden Link klicken. Es
    wird ein Opt-Out-Cookie gesetzt, der die Erfassung Ihrer Daten bei zukünftigen Besuchen dieser Website verhindert:
    <a>Google Analytics deaktivieren</a>
  </p>
  <p><strong>Auftragsdatenverarbeitung</strong></p>
  <p>
    Wir haben mit Google einen Vertrag zur Auftragsdatenverarbeitung abgeschlossen und setzen die strengen Vorgaben der
    deutschen Datenschutzbehörden bei der Nutzung von Google Analytics vollständig um.
  </p>
  <p><strong>IP-Anonymisierung</strong></p>
  <p>
    Wir nutzen die Funktion "Aktivierung der IP-Anonymisierung" auf dieser Webseite. Dadurch wird Ihre IP-Adresse von
    Google jedoch innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens
    über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server
    von Google in den USA übertragen und dort gekürzt. Im Auftrag des Betreibers dieser Website wird Google diese
    Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten
    zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen
    gegenüber dem Websitebetreiber zu erbringen. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte
    IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt.
  </p>
  <p><strong>Demografische Merkmale bei Google Analytics</strong></p>
  <p>
    Diese Website nutzt die Funktion “demografische Merkmale” von Google Analytics. Dadurch können Berichte erstellt
    werden, die Aussagen zu Alter, Geschlecht und Interessen der Seitenbesucher enthalten. Diese Daten stammen aus
    interessenbezogener Werbung von Google sowie aus Besucherdaten von Drittanbietern. Diese Daten können keiner
    bestimmten Person zugeordnet werden. Sie können diese Funktion jederzeit über die Anzeigeneinstellungen in Ihrem
    Google-Konto deaktivieren oder die Erfassung Ihrer Daten durch Google Analytics wie im Punkt “Widerspruch gegen
    Datenerfassung” dargestellt generell untersagen.
  </p>
  <p>&nbsp;</p>
  <h2>Wordpress Stats</h2>
  <p>
    Diese Website nutzt das WordPress Tool Stats um Besucherzugriffe statistisch auszuwerten. Anbieter ist die
    Automattic Inc., 60 29th Street #343, San Francisco, CA 94110-4929, USA.
  </p>
  <p>
    Wordpress Stats verwendet Cookies, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der
    Website erlauben. Die durch die Cookies generierten Informationen über die Benutzung unserer Webseite werden auf
    Servern in den USA gespeichert. Ihre IP-Adresse wird nach der Verarbeitung und vor der Speicherung anonymisiert.
  </p>
  <p>
    Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und Cookies nur im
    Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie das automatische
    Löschen der Cookies beim Schließen des Browser aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität
    unserer Webseite eingeschränkt sein.
  </p>
  <p>
    Sie können der Erhebung und Nutzung Ihrer Daten für die Zukunft widersprechen, indem Sie mit einem Klick auf diesen
    Link einen Opt-Out-Cookie in Ihrem Browser setzen:
    <a href="https://www.quantcast.com/opt-out/">https://www.quantcast.com/opt-out/</a>
  </p>
  <p>Wenn Sie die Cookies auf Ihrem Rechner löschen, müssen Sie den Opt-Out-Cookie erneut setzen.</p>
  <p>&nbsp;</p>
  <h2>Facebook-Plugins (Like-Button)</h2>
  <p>
    Auf unseren Seiten sind Plugins des sozialen Netzwerks Facebook, Anbieter Facebook Inc., 1 Hacker Way, Menlo Park,
    California 94025, USA, integriert. Die Facebook-Plugins erkennen Sie an dem Facebook-Logo oder dem "Like-Button"
    ("Gefällt mir") auf unserer Seite. Eine Übersicht über die Facebook-Plugins finden Sie hier:
    <a href="http://developers.facebook.com/docs/plugins/">http://developers.facebook.com/docs/plugins/</a>.
  </p>
  <p>
    Wenn Sie unsere Seiten besuchen, wird über das Plugin eine direkte Verbindung zwischen Ihrem Browser und dem
    Facebook-Server hergestellt. Facebook erhält dadurch die Information, dass Sie mit Ihrer IP-Adresse unsere Seite
    besucht haben. Wenn Sie den Facebook "Like-Button" anklicken während Sie in Ihrem Facebook-Account eingeloggt sind,
    können Sie die Inhalte unserer Seiten auf Ihrem Facebook-Profil verlinken. Dadurch kann Facebook den Besuch unserer
    Seiten Ihrem Benutzerkonto zuordnen. Wir weisen darauf hin, dass wir als Anbieter der Seiten keine Kenntnis vom
    Inhalt der übermittelten Daten sowie deren Nutzung durch Facebook erhalten. Weitere Informationen hierzu finden Sie
    in der Datenschutzerklärung von Facebook unter
    <a href="http://de-de.facebook.com/policy.php">http://de-de.facebook.com/policy.php</a>.
  </p>
  <p>
    Wenn Sie nicht wünschen, dass Facebook den Besuch unserer Seiten Ihrem Facebook-Nutzerkonto zuordnen kann, loggen
    Sie sich bitte aus Ihrem Facebook-Benutzerkonto aus.
  </p>
  <p>&nbsp;</p>
  <h2>Facebook-Pixel</h2>
  <p>
    Unsere Webseite nutzt zur Konversionsmessung das Besucheraktions-Pixel von Facebook, Facebook Inc. 1601 S.
    California Ave, Palo Alto, CA 94304, USA (“Facebook”) . So kann das Verhalten der Seitenbesucher nachverfolgt
    werden, nachdem diese durch Klick auf eine Facebook-Werbeanzeige auf die Webseite des Anbieters weitergeleitet
    wurden. Dadurch können die Wirksamkeit der Facebook-Werbeanzeigen für statistische und Marktforschungszwecke
    ausgewertet werden und zukünftige Werbemaßnahmen optimiert werden.
  </p>
  <p>
    Die erhobenen Daten sind für uns als Betreiber dieser Webseite anonym, wir können keine Rückschlüsse auf die
    Identität der Nutzer ziehen. Die Daten werden aber von Facebook gespeichert und verarbeitet, sodass eine Verbindung
    zum jeweiligen Nutzerprofil möglich ist und Facebook die Daten für eigene Werbezwecke, entsprechend der Facebook-
    Datenverwendungsrichtlinie (<a href="https://www.facebook.com/about/privacy/"
      >https://www.facebook.com/about/privacy/</a
    >) verwenden kann. Dadurch kann Facebook das Schalten von Werbeanzeigen auf Seiten von Facebook sowie außerhalb von
    Facebook ermöglichen. Diese Verwendung der Daten kann von uns als Seitenbetreiber nicht beeinflusst werden.
  </p>
  <p>
    In den Datenschutzhinweisen von Facebook finden Sie weitere Hinweise zum Schutz Ihrer Privatsphäre
    <a href="https://www.facebook.com/about/privacy/">https://www.facebook.com/about/privacy/</a>.
  </p>
  <p>
    Sie können außerdem die Remarketing-Funktion „Custom Audiences“ unter
    <a href="https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen"
      >https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen</a
    >
    deaktivieren. Dazu müssen Sie bei Facebook angemeldet sein.
  </p>
  <p>
    Wenn Sie kein Facebook Konto besitzen können Sie nutzungsbasierte Werbung von Facebook auf der Webseite der European
    Interactive Digital Advertising Alliance deaktivieren:
    <a href="http://www.youronlinechoices.com/de/praferenzmanagement/"
      >http://www.youronlinechoices.com/de/praferenzmanagement/</a
    >
  </p>
  <p>&nbsp;</p>
  <h2>Twitter</h2>
  <p>
    Auf unseren Seiten sind Funktionen des Dienstes Twitter eingebunden. Diese Funktionen werden angeboten durch die
    Twitter Inc., 1355 Market Street, Suite 900, San Francisco, CA 94103, USA. Durch das Benutzen von Twitter und der
    Funktion "Re-Tweet" werden die von Ihnen besuchten Webseiten mit Ihrem Twitter-Account verknüpft und anderen Nutzern
    bekannt gegeben. Dabei werden auch Daten an Twitter übertragen. Wir weisen darauf hin, dass wir als Anbieter der
    Seiten keine Kenntnis vom Inhalt der übermittelten Daten sowie deren Nutzung durch Twitter erhalten. Weitere
    Informationen hierzu finden Sie in der Datenschutzerklärung von Twitter unter
    <a href="http://twitter.com/privacy">http://twitter.com/privacy</a>.
  </p>
  <p>
    Ihre Datenschutzeinstellungen bei Twitter können Sie in den Konto-Einstellungen unter:
    <a href="http://twitter.com/account/settings">http://twitter.com/account/settings</a>
    ändern.
  </p>
  <p>&nbsp;</p>
  <h2>Google+</h2>
  <p>
    Unsere Seiten nutzen Funktionen von Google+. Anbieter ist die Google Inc., 1600 Amphitheatre Parkway Mountain View,
    CA 94043, USA.
  </p>
  <p>
    Erfassung und Weitergabe von Informationen: Mit Hilfe der Google+-Schaltfläche können Sie Informationen weltweit
    veröffentlichen. Über die Google+-Schaltfläche erhalten Sie und andere Nutzer personalisierte Inhalte von Google und
    unseren Partnern. Google speichert sowohl die Information, dass Sie für einen Inhalt +1 gegeben haben, als auch
    Informationen über die Seite, die Sie beim Klicken auf +1 angesehen haben. Ihre +1 können als Hinweise zusammen mit
    Ihrem Profilnamen und Ihrem Foto in Google-Diensten, wie etwa in Suchergebnissen oder in Ihrem Google-Profil, oder
    an anderen Stellen auf Websites und Anzeigen im Internet eingeblendet werden.
  </p>
  <p>
    Google zeichnet Informationen über Ihre +1-Aktivitäten auf, um die Google-Dienste für Sie und andere zu verbessern.
    Um die Google+-Schaltfläche verwenden zu können, benötigen Sie ein weltweit sichtbares, öffentliches Google-Profil,
    das zumindest den für das Profil gewählten Namen enthalten muss. Dieser Name wird in allen Google-Diensten
    verwendet. In manchen Fällen kann dieser Name auch einen anderen Namen ersetzen, den Sie beim Teilen von Inhalten
    über Ihr Google-Konto verwendet haben. Die Identität Ihres Google-Profils kann Nutzern angezeigt werden, die Ihre
    E-Mail-Adresse kennen oder über andere identifizierende Informationen von Ihnen verfügen.
  </p>
  <p>
    Verwendung der erfassten Informationen: Neben den oben erläuterten Verwendungszwecken werden die von Ihnen
    bereitgestellten Informationen gemäß den geltenden Google-Datenschutzbestimmungen genutzt. Google veröffentlicht
    möglicherweise zusammengefasste Statistiken über die +1-Aktivitäten der Nutzer bzw. gibt diese an Nutzer und Partner
    weiter, wie etwa Publisher, Inserenten oder verbundene Websites.
  </p>
  <p>&nbsp;</p>
  <h2>Instagram</h2>
  <p>
    Auf unseren Seiten sind Funktionen des Dienstes Instagram eingebunden. Diese Funktionen werden angeboten durch die
    Instagram Inc., 1601 Willow Road, Menlo Park, CA, 94025, USA integriert. Wenn Sie in Ihrem Instagram-Account
    eingeloggt sind können Sie durch Anklicken des Instagram-Buttons die Inhalte unserer Seiten mit Ihrem
    Instagram-Profil verlinken. Dadurch kann Instagram den Besuch unserer Seiten Ihrem Benutzerkonto zuordnen. Wir
    weisen darauf hin, dass wir als Anbieter der Seiten keine Kenntnis vom Inhalt der übermittelten Daten sowie deren
    Nutzung durch Instagram erhalten.
  </p>
  <p>
    Weitere Informationen hierzu finden Sie in der Datenschutzerklärung von Instagram:
    <a href="http://instagram.com/about/legal/privacy/">http://instagram.com/about/legal/privacy/</a>
  </p>
  <p>&nbsp;</p>
  <h2>LinkedIn</h2>
  <p>
    Unsere Website nutzt Funktionen des Netzwerks LinkedIn. Anbieter ist die LinkedIn Corporation, 2029 Stierlin Court,
    Mountain View, CA 94043, USA. Bei jedem Abruf einer unserer Seiten, die Funktionen von LinkedIn enthält, wird eine
    Verbindung zu Servern von LinkedIn aufbaut. LinkedIn wird darüber informiert, dass Sie unsere Internetseiten mit
    Ihrer IP-Adresse besucht haben. Wenn Sie den "Recommend-Button" von LinkedIn anklicken und in Ihrem Account bei
    LinkedIn eingeloggt sind, ist es LinkedIn möglich, Ihren Besuch auf unserer Internetseite Ihnen und Ihrem
    Benutzerkonto zuzuordnen. Wir weisen darauf hin, dass wir als Anbieter der Seiten keine Kenntnis vom Inhalt der
    übermittelten Daten sowie deren Nutzung durch LinkedIn haben.
  </p>
  <p>
    Weitere Informationen hierzu finden Sie in der Datenschutzerklärung von LinkedIn unter:
    <a href="https://www.linkedin.com/legal/privacy-policy">https://www.linkedin.com/legal/privacy-policy</a>
  </p>
  <p>&nbsp;</p>
  <h2>Pinterest</h2>
  <p>
    Auf unserer Seite verwenden wir Social Plugins des sozialen Netzwerkes Pinterest, das von der Pinterest Inc., 808
    Brannan Street San Francisco, CA 94103-490, USA ("Pinterest") betrieben wird. Wenn Sie eine Seite aufrufen, die ein
    solches Plugin enthält, stellt Ihr Browser eine direkte Verbindung zu den Servern von Pinterest her. Das Plugin
    übermittelt dabei Protokolldaten an den Server von Pinterest in die USA. Diese Protokolldaten enthalten
    möglicherweise Ihre IP-Adresse, die Adresse der besuchten Websites, die ebenfalls Pinterest-Funktionen enthalten,
    Art und Einstellungen des Browsers, Datum und Zeitpunkt der Anfrage, Ihre Verwendungsweise von Pinterest sowie
    Cookies.
  </p>
  <p>
    Weitere Informationen zu Zweck, Umfang und weiterer Verarbeitung und Nutzung der Daten durch Pinterest sowie Ihre
    diesbezüglichen Rechte und Möglichkeiten zum Schutz Ihrer Privatsphäre finden Sie in den den Datenschutzhinweisen
    von Pinterest:
    <a href="https://about.pinterest.com/de/privacy-policy">https://about.pinterest.com/de/privacy-policy</a>
  </p>
  <p>&nbsp;</p>
  <h2>Google Web Fonts</h2>
  <p>
    Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so genannte Web Fonts, die von Google
    bereitgestellt werden. Beim Aufruf einer Seite lädt Ihr Browser die benötigten Web Fonts in ihren Browsercache, um
    Texte und Schriftarten korrekt anzuzeigen. Wenn Ihr Browser Web Fonts nicht unterstützt, wird eine Standardschrift
    von Ihrem Computer genutzt.
  </p>
  <p>
    Weitere Informationen zu Google Web Fonts finden Sie unter
    <a href="https://developers.google.com/fonts/faq">https://developers.google.com/fonts/faq</a>
    und in der Datenschutzerklärung von Google:
    <a href="https://www.google.com/policies/privacy/">https://www.google.com/policies/privacy/</a>
  </p>
  <p>&nbsp;</p>
  <h2>Google Maps</h2>
  <p>
    Diese Seite nutzt über eine API den Kartendienst Google Maps. Anbieter ist die Google Inc., 1600 Amphitheatre
    Parkway Mountain View, CA 94043, USA. Zur Nutzung der Funktionen von Google Maps ist es notwendig, Ihre IP Adresse
    zu speichern. Diese Informationen werden in der Regel an einen Server von Google in den USA übertragen und dort
    gespeichert. Der Anbieter dieser Seite hat keinen Einfluss auf diese Datenübertragung.
  </p>
  <p>
    Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in der Datenschutzerklärung von Google:
    <a href="https://www.google.de/intl/de/policies/privacy/">https://www.google.de/intl/de/policies/privacy/</a>
  </p>
  <p>&nbsp;</p>
  <h2>XING</h2>
  <p>
    Unsere Webseite nutzt Funktionen des Netzwerks XING. Anbieter ist die XING AG, Dammtorstraße 29-32, 20354 Hamburg,
    Deutschland. Bei jedem Abruf einer unserer Seiten, die Funktionen von XING enthält, wird eine Verbindung zu Servern
    von XING hergestellt. Eine Speicherung von personenbezogenen Daten erfolgt dabei nach unserer Kenntnis nicht.
    Insbesondere werden keine IP-Adressen gespeichert oder das Nutzungsverhalten ausgewertet.
  </p>
  <p>
    Weitere Information zum Datenschutz und dem XING Share-Button finden Sie in der Datenschutzerklärung von XING unter:
    <a href="https://www.xing.com/app/share?op=data_protection">https://www.xing.com/app/share?op=data_protection</a>
  </p>
  <p>&nbsp;</p>
  <h2>YouTube</h2>
  <p>
    Unsere Webseite nutzt Plugins der von Google betriebenen Seite YouTube. Betreiber der Seiten ist die YouTube, LLC,
    901 Cherry Ave., San Bruno, CA 94066, USA. Wenn Sie eine unserer mit einem YouTube-Plugin ausgestatteten Seiten
    besuchen, wird eine Verbindung zu den Servern von YouTube hergestellt. Dabei wird dem Youtube-Server mitgeteilt,
    welche unserer Seiten Sie besucht haben.
  </p>
  <p>
    Wenn Sie in Ihrem YouTube-Account eingeloggt sind ermöglichen Sie YouTube, Ihr Surfverhalten direkt Ihrem
    persönlichen Profil zuzuordnen. Dies können Sie verhindern, indem Sie sich aus Ihrem YouTube-Account ausloggen.
  </p>
  <p>
    Weitere Informationen zum Umgang von Nutzerdaten finden Sie in der Datenschutzerklärung von YouTube unter:
    <a href="https://www.google.de/intl/de/policies/privacy">https://www.google.de/intl/de/policies/privacy</a>
  </p>
  <p>&nbsp;</p>
  <h2>Google AdSense</h2>
  <p>
    Diese Website benutzt Google AdSense, einen Dienst zum Einbinden von Werbeanzeigen der Google Inc. ("Google").
    Anbieter ist die Google Inc., 1600 Amphitheatre Parkway Mountain View, CA 94043, USA.
  </p>
  <p>
    Google AdSense verwendet so genannte "Cookies", Textdateien, die auf Ihrem Computer gespeichert werden und die eine
    Analyse der Benutzung der Website ermöglicht. Google AdSense verwendet auch so genannte Web Beacons (unsichtbare
    Grafiken). Durch diese Web Beacons können Informationen wie der Besucherverkehr auf diesen Seiten ausgewertet
    werden.
  </p>
  <p>
    Die durch Cookies und Web Beacons erzeugten Informationen über die Benutzung dieser Website (einschließlich Ihrer
    IP-Adresse) und Auslieferung von Werbeformaten werden an einen Server von Google in den USA übertragen und dort
    gespeichert. Diese Informationen können von Google an Vertragspartner von Google weitergegeben werden. Google wird
    Ihre IP-Adresse jedoch nicht mit anderen von Ihnen gespeicherten Daten zusammenführen.
  </p>
  <p>
    Sie können die Installation der Cookies durch eine entsprechende Einstellung Ihrer Browser Software verhindern; wir
    weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website voll
    umfänglich nutzen können. Durch die Nutzung dieser Website erklären Sie sich mit der Bearbeitung der über Sie
    erhobenen Daten durch Google in der zuvor beschriebenen Art und Weise und zu dem zuvor benannten Zweck
    einverstanden.
  </p>
  <p>&nbsp;</p>
  <h2>Google Analytics Remarketing</h2>
  <p>
    Unsere Webseiten nutzen die Funktionen von Google Analytics Remarketing in Verbindung mit den geräteübergreifenden
    Funktionen von Google AdWords und Google DoubleClick. Anbieter ist die Google Inc. 1600 Amphitheatre Parkway
    Mountain View, CA 94043, USA.
  </p>
  <p>
    Diese Funktion ermöglicht es die mit Google Analytics Remarketing erstellten Werbe-Zielgruppen mit den
    geräteübergreifenden Funktionen von Google AdWords und Google-DoubleClick zu verknüpfen. Auf diese Weise können
    interessenbezogene, personalisierte Werbebotschaften, die in Abhängigkeit Ihres früheren Nutzungs- und
    Surfverhaltens auf einem Endgerät (z.B. Handy) an Sie angepasst wurden auch auf einem anderen Ihrer Endgeräte (z.B.
    Tablet oder PC) angezeigt werden.
  </p>
  <p>
    Haben Sie eine entsprechende Einwilligung erteilt, verknüpft Google zu diesem Zweck Ihren Web- und
    App-Browserverlauf mit Ihrem Google-Konto. Auf diese Weise können auf jedem Endgerät auf dem Sie sich mit Ihrem
    Google-Konto anmelden, dieselben personalisierten Werbebotschaften geschaltet werden.
  </p>
  <p>
    Zur Unterstützung dieser Funktion erfasst Google Analytics google-authentifizierte ID´s der Nutzer, die
    vorübergehend mit unseren Google-Analytics-Daten verknüpft werden, um Zielgruppen für die geräteübergreifende
    Anzeigenwerbung zu definieren und zu erstellen.
  </p>
  <p>
    Sie können dem geräteübergreifenden Remarketing/Targeting dauerhaft widersprechen, indem Sie personalisierte Werbung
    in Ihrem Google-Konto deaktivieren; folgen Sie hierzu diesem Link:
    <a href="https://www.google.com/settings/ads/onweb/">https://www.google.com/settings/ads/onweb/</a>
  </p>
  <p>
    Weitergehende Informationen und die Datenschutzbestimmungen finden Sie in der Datenschutzerklärung von Google unter:
    <a href="http://www.google.com/policies/technologies/ads/">http://www.google.com/policies/technologies/ads/</a>
  </p>
  <p>&nbsp;</p>
  <h2>Google AdWords und Google Conversion Tracking</h2>
  <p>
    Diese Webseite verwendet Google AdWords. AdWords ist ein Online-Werbeprogramm der Google Inc., 1600 Amphitheatre
    Parkway, Mountain View, CA 94043, United States (“Google”).
  </p>
  <p>
    Im Rahmen von Google AdWords nutzen wir das so genannte Conversion-Tracking. Wenn Sie auf eine von Google
    geschaltete Anzeige klicken wird ein Cookie für das Conversion-Tracking gesetzt. Bei Cookies handelt es sich um
    kleine Textdateien, die der Internet-Browser auf dem Computer des Nutzers ablegt. Diese Cookies verlieren nach 30
    Tagen ihre Gültigkeit und dienen nicht der persönlichen Identifizierung der Nutzer. Besucht der Nutzer bestimmte
    Seiten dieser Website und das Cookie ist noch nicht abgelaufen, können Google und wir erkennen, dass der Nutzer auf
    die Anzeige geklickt hat und zu dieser Seite weitergeleitet wurde.
  </p>
  <p>
    Jeder Google AdWords-Kunde erhält ein anderes Cookie. Die Cookies können nicht über die Websites von AdWords-Kunden
    nachverfolgt werden. Die mithilfe des Conversion-Cookies eingeholten Informationen dienen dazu,
    Conversion-Statistiken für AdWords-Kunden zu erstellen, die sich für Conversion-Tracking entschieden haben. Die
    Kunden erfahren die Gesamtanzahl der Nutzer, die auf ihre Anzeige geklickt haben und zu einer mit einem
    Conversion-Tracking-Tag versehenen Seite weitergeleitet wurden. Sie erhalten jedoch keine Informationen, mit denen
    sich Nutzer persönlich identifizieren lassen. Wenn Sie nicht am Tracking teilnehmen möchten, können Sie dieser
    Nutzung widersprechen, indem Sie das Cookie des Google Conversion-Trackings über ihren Internet-Browser unter
    Nutzereinstellungen leicht deaktivieren. Sie werden sodann nicht in die Conversion-Tracking Statistiken aufgenommen.
  </p>
  <p>
    Mehr Informationen zu Google AdWords und Google Conversion-Tracking finden Sie in den Datenschutzbestimmungen von
    Google:
    <a href="http://www.google.de/policies/privacy/">http://www.google.de/policies/privacy/</a>
  </p>
  <p>
    Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und Cookies nur im
    Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie das automatische
    Löschen der Cookies beim Schließen des Browser aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität
    dieser Website eingeschränkt sein.
  </p>
  <p>&nbsp;</p>
  <h2>Registrierung auf dieser Webseite</h2>
  <p>
    Sie können sich auf unserer Webseite registrieren, um zusätzliche Funktionen auf der Seite zu nutzen. Die dazu
    eingegebenen Daten verwenden wir nur zum Zwecke der Nutzung des jeweiligen Angebotes oder Dienstes, für den Sie sich
    registriert haben.
  </p>
  <p>
    Für wichtige Änderungen etwa beim Angebotsumfang oder bei technisch notwendigen Änderungen nutzen wir die bei der
    Registrierung angegebene E-Mail-Adresse, um Sie auf diesem Wege zu informieren.
  </p>
  <p>&nbsp;</p>
  <h2>Kommentarfunktion auf dieser Webseite</h2>
  <p>
    Für die Kommentarfunktion auf dieser Seite werden neben Ihrem Kommentar auch Angaben zum Zeitpunkt der Erstellung
    des Kommentars, Ihre E-Mail-Adresse und, wenn Sie nicht anonym posten, der von Ihnen gewählte Nutzername
    gespeichert.
  </p>
  <p><strong>Speicherung der IP Adresse</strong></p>
  <p>
    Unsere Kommentarfunktion speichert die IP-Adressen der Nutzer, die Kommentare verfassen. Da wir Kommentare auf
    unserer Seite nicht vor der Freischaltung prüfen, benötigen wir diese Daten, um im Falle von Rechtsverletzungen wie
    Beleidigungen oder Propaganda gegen den Verfasser vorgehen zu können.
  </p>
  <p><strong>Abonnieren von Kommentaren</strong></p>
  <p>
    Als Nutzer der Seite können Sie nach einer Anmeldung Kommentare abonnieren. Sie erhalten eine Bestätigungsemail, um
    zu prüfen, ob Sie der Inhaber der angegebenen E-Mail-Adresse sind. Sie können diese Funktion jederzeit über einen
    Link in den Info-Mails abbestellen.
  </p>
  <p>&nbsp;</p>
  <h2>SSL-Verschlüsselung</h2>
  <p>
    Diese Seite nutzt aus Gründen der Sicherheit und zum Schutz der Übertragung vertraulicher Inhalte, wie zum Beispiel
    der Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL-Verschlüsselung. Eine verschlüsselte Verbindung
    erkennen Sie daran, dass die Adresszeile des Browsers von "http://" auf "https://" wechselt und an dem
    Schloss-Symbol in Ihrer Browserzeile.
  </p>
  <p>
    Wenn die SSL Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht von Dritten
    mitgelesen werden.
  </p>
  <p>&nbsp;</p>
  <h2>Recht auf Auskunft, Löschung, Sperrung</h2>
  <p>
    Sie haben jederzeit das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, deren
    Herkunft und Empfänger und den Zweck der Datenverarbeitung sowie ein Recht auf Berichtigung, Sperrung oder Löschung
    dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit unter der
    im Impressum angegebenen Adresse an uns wenden.
  </p>
  <p>&nbsp;</p>
  <h2>Widerspruch Werbe-Mails</h2>
  <p>
    Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten zur Übersendung von nicht ausdrücklich
    angeforderter Werbung und Informationsmaterialien wird hiermit widersprochen. Die Betreiber der Seiten behalten sich
    ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen, etwa durch
    Spam-E-Mails, vor.
  </p>
  <p>&nbsp;</p>
  <h2>Information an den Betroffenen nach Artikel 13 EU Datenschutz-Grundverordnung</h2>
  <p>
    Aufgrund einer Forderung aus der am 25. Mai 2018 in Kraft getretenen EU Datenschutz-Grundverordnung (DSGVO) sind
    wir, die MCC Medical CareCapital GmbH, Hohenzollernstr. 47, 47799 Krefeld verpflichtet, Sie nach Artikel 13 der
    DSGVO ausführlich über die Verarbeitung Ihrer personenbezogenen Daten auf unserer Webseite (nachfolgend nur noch
    „Daten“ genannt) aufzuklären.
  </p>
  <p>
    Dieser Verpflichtung kommen wir zusammen mit unserer Datenschutzbeauftragten Herr Joachim Kramer, Datenschutz Kramer
    &amp; Kramer GmbH, Richard-Wagner-Str. 11, 01445 Radebeul, info&#64;datenschutz-kramer.de gerne nach.
  </p>
  <p>
    Nachfolgend erläutern wir, welche Daten wir von Ihnen zu welchen Zwecken verarbeiten und welche Rechte Sie
    diesbezüglich haben.
  </p>
  <h2>Zwecke der Verarbeitung</h2>
  <p>
    Die Daten, die Sie uns freiwillig über unsere Webseite zur Verfügung stellen, verwenden wir für folgende Zwecke:
  </p>
  <ul style="list-style: circle; margin-left: 1em">
    <li>
      nach Artikel 6 Abs. 1 a) der DSGVO auf der Basis des von Ihnen freiwillig ausgefüllten Kontaktformulars
      <ul style="list-style: circle; margin-left: 1em">
        <li>zur Kontaktaufnahme mit Ihnen</li>
        <li>zur Beantwortung konkreter Anfragen</li>
      </ul>
    </li>
  </ul>
  <h2>Kategorien der verarbeiteten Daten</h2>
  <ul style="list-style: circle; margin-left: 1em">
    <li>Name, Vorname</li>
    <li>E-Mail Adresse</li>
    <li>Kontaktdaten</li>
    <li>freier Nachrichtentext</li>
    <li>Geburtsdatum</li>
    <li>hochgeladene Dokumente</li>
  </ul>
  <h2>Kategorien von Empfängern</h2>
  <p>
    Eine Übermittlung an Dritte findet nicht statt. Eine Übermittlung Ihrer Daten findet ausschließlich an Unternehmen
    innerhalb der opta data Gruppe statt.
  </p>
  <h2>Dauer der Verarbeitung</h2>
  <p>
    Wir benötigen Ihre Daten nur so lange, wie es zur Erfüllung des oben genannten Zwecks erforderlich ist.
    Geschäftliche Unterlagen und Anfragen werden entsprechend den Vorgaben des Handelsgesetzbuches und der
    Abgabenordnung höchstens 6 und 10 Jahre lang aufbewahrt und danach gemäß DIN 66399 vernichtet. Bewerberdaten
    bewahren wir 6 Monate zur Erfüllung der gesetzlichen Verpflichtung nach § 61 Abs. 1 ArbGG i.V.m. § 15 AGG auf und
    vernichten diese anschließend gem. DIN 66399.
  </p>
  <h2>Ihre Rechte als betroffene Person</h2>
  <p>Nach der DSGVO haben Sie das Recht auf:</p>
  <ul style="list-style: circle; margin-left: 1em">
    <li>Auskunft über die bei uns über Sie gespeicherten Daten</li>
    <li>Berichtigung Ihrer Daten wenn diese nachweislich falsch sind</li>
    <li>Löschung Ihrer Daten (wenn keine gesetzlichen Aufbewahrungsfristen entgegenstehen)</li>
    <li>Einschränkung der Verarbeitung (nur noch Speicherung möglich)</li>
    <li>Widerspruch gegen die Verarbeitung</li>
    <li>Datenübertragbarkeit</li>
    <li>Widerruf einer evtl. gegebenen Einwilligung mit Wirkung auf die Zukunft</li>
    <li>
      Beschwerde bei der Datenschutz-Aufsichtsbehörde. Die für uns zuständige Aufsichtsbehörde ist:<br />Landesbeauftragte
      für Datenschutz und Informationsfreiheit<br />Nordrhein-Westfalen<br />Postfach 20 04 44<br />40102 Düsseldorf
    </li>
  </ul>
  <p>Wir hoffen Ihnen mit diesen Informationen bei der Wahrnehmung Ihrer Rechte geholfen zu haben.</p>
  <h2>Datenschutzbeauftragter:</h2>
  <p>
    Joachim Kramer<br />Datenschutz Kramer &amp; Kramer GmbH<br />Richard-Wagner-Str. 11<br />D-01445 Radebeul<br />Tel.:
    +49 351–83 38 715<br />Fax: +49 351–83 38 716<br />E-Mail: info&#64;datenschutz-kramer.de
  </p>
</div>
<div *ngIf="!langIsMcc">
  <div class="header-container">
    <h1 class="ps-5">Datenschutzerklärung</h1>
    <p class="ps-5">optadata.de</p>
  </div>
  <div class="m-5">
    <a
      href="https://www.optadata-gruppe.de/fileadmin/user_upload/datenschutz/Anlage_U_Datenschutzerklaerung_Artikel_13.pdf"
      target="_blank"
      >Informationsschreiben nach Artikel 13</a
    ><br /><br />

    <a
      href="https://www.optadata-gruppe.de/fileadmin/user_upload/datenschutz/odFIN_Artikel_14_Stand_12-2021.pdf"
      target="_blank"
      >Informationsschreiben nach Artikel 14</a
    >

    <h2 class="mt-5">1. Wer ist verantwortlich für die Datenerfassung auf dieser Website?</h2>
    <p>
      Verantwortlicher im Sinne der Datenschutz-Grundverordnung, sonstiger in den Mitgliedstaaten der Europäischen Union
      geltenden Datenschutzgesetze und anderer Bestimmungen mit datenschutzrechtlichem Charakter ist die
    </p>

    <p>
      opta data Finance GmbH<br />
      Berthold-Beitz-Boulevard 461<br />
      D-45141 Essen
    </p>

    <p>
      Telefon: 0201 3196-0<br />
      Fax: 0201 3196-222<br />
      E-Mail:
      <a href="mailto:service@optadata-gruppe.de">service&#64;optadata-gruppe.de</a>
    </p>

    <h2 class="mt-5">Datenschutzbeauftragter</h2>

    <p>Wir haben für unser Unternehmen einen Datenschutzbeauftragten bestellt.</p>

    <p>
      Datenschutz Kramer und Kramer GmbH<br />
      Büro für Datenschutz und Datensicherheit<br />
      Elsternweg 24<br />
      D-42555 Velbert
    </p>

    <p>
      Telefon: 02052 9289766<br />
      E-Mail:
      <a href="mailto:info@datenschutz-kramer.de">info&#64;datenschutz-kramer.de</a>
    </p>

    <h2 class="mt-5">2. Erhebung und Speicherung personenbezogener Daten sowie Art und Zweck von deren Verwendung</h2>

    <h4 class="mt-3">a) Beim Besuch der Website</h4>

    <p>
      Beim Aufrufen unserer Website werden durch den auf Ihrem Endgerät zum Einsatz kommenden Browser automatisch
      Informationen an den Server unserer Website gesendet. Diese Informationen werden temporär in einem sog. Logfile
      gespeichert. Folgende Informationen werden dabei ohne Ihr Zutun erfasst und bis zur automatisierten Löschung
      gespeichert:
    </p>

    <ul class="list-group-list">
      <li>anonymisierte IP-Adresse des anfragenden Rechners,</li>
      <li>Datum und Uhrzeit des Zugriffs,</li>
      <li>Name und URL der abgerufenen Datei,</li>
      <li>Website, von der aus der Zugriff erfolgt (Referrer-URL),</li>
      <li>verwendeter Browser und ggf. das Betriebssystem Ihres Rechners sowie der Name Ihres Access-Providers.</li>
    </ul>

    <br />

    <p>Die genannten Daten werden durch uns zu folgenden Zwecken verarbeitet:</p>

    <ul class="list-group-list">
      <li>Gewährleistung eines reibungslosen Verbindungsaufbaus der Website,</li>
      <li>Gewährleistung einer komfortablen Nutzung unserer Website,</li>
      <li>Auswertung der Systemsicherheit und -stabilität sowie</li>
      <li>zu weiteren administrativen Zwecken.</li>
    </ul>

    <p>
      Die Rechtsgrundlage für die Datenverarbeitung ist Art. 6 Abs. 1 S. 1 lit. f DSGVO. Unser berechtigtes Interesse
      folgt aus oben aufgelisteten Zwecken zur Datenerhebung. In keinem Fall verwenden wir die erhobenen Daten zu dem
      Zweck, Rückschlüsse auf Ihre Person zu ziehen.
    </p>

    <h4 class="mt-3">b) Bei Anmeldung für unseren Newsletter</h4>

    <p>
      Sofern Sie nach Art. 6 Abs. 1 S. 1 lit. a DSGVO ausdrücklich eingewilligt haben, verwenden wir Ihre E-Mail-Adresse
      dafür, Ihnen regelmäßig unseren Newsletter zu übersenden.
    </p>
    <p>
      Der Einsatz des Versanddienstleisters, Durchführung statistischer Erhebungen und Analysen sowie Protokollierung
      des Anmeldeverfahrens erfolgt durch das Pardot Marketing Automation System ("Pardot MAS") auf Grundlage unserer
      berechtigten Interessen gemäß Art. 6 Abs. 1 lit. f DSGVO. Pardot wird die hier bezeichneten Daten nicht an Dritte
      weiterleiten. Pardot MAS ist ein Analysedienst der Pardot LLC, 950 E. Paces Ferry Rd. Suite 3300 Atlanta, GA
      30326, USA ("Pardot"). Pardot verwendet sogenannte „Cookies", Textdateien, die auf Ihrem Computer gespeichert
      werden und die eine Analyse der Benutzung des Newsletters durch Sie ermöglichen. Indem Sie bei erstmaliger Nutzung
      unserer Website über die Nutzung von Cookies aufgeklärt werden und die Cookie-Einstellungen selbst vornehmen
      können, willigen Sie in den Einsatz von Cookies ein.
    </p>
    <p>
      Sie können Ihre Einwilligung zum Empfang unseres Newsletters jederzeit widerrufen. Die Abmeldung hierfür kann
      unter dem Link im Newsletter mit sofortiger Wirkung oder durch Ihre Mitteilung per E-Mail an
      <a href="mailto:service@optadata-gruppe.de">service&#64;optadata-gruppe.de</a>
      erfolgen. Bitte beachten Sie, dass bis zur Bearbeitung und Umsetzung eines Widerrufes des Newsletters per E-Mail
      bis zu 7 Werktagen vergehen können und bis dahin ein erneuter Newsletter versandt werden kann. Soweit wir Ihre
      personenbezogenen Daten zur Direktwerbung nutzen, können Sie dem gemäß Art. 21 DSGVO jederzeit durch Mitteilung an
      uns widersprechen.
    </p>
    <p>
      Die Einwilligung in die Nutzung von Cookies wird über Usercentrics sichergestellt. Die Usercentrics GmbH,
      Sendinger Str. 7, 80331 München erhebt dabei nur anonymisierte Daten zu statistischen Zwecken.
    </p>

    <h4 class="mt-3">c) Bei Nutzung unseres Kontaktformulars</h4>

    <p>
      Bei Fragen jeglicher Art bieten wir Ihnen die Möglichkeit, mit uns über ein auf der Website bereitgestelltes
      Formular Kontakt aufzunehmen. Dabei ist die Angabe einer gültigen E-Mail-Adresse erforderlich, damit wir wissen,
      von wem die Anfrage stammt und um diese beantworten zu können. Weitere Angaben können freiwillig getätigt werden.
    </p>
    <p>
      Die Datenverarbeitung zum Zwecke der Kontaktaufnahme mit uns erfolgt nach Art. 6 Abs. 1 S. 1 lit. a DSGVO auf
      Grundlage Ihrer freiwillig erteilten Einwilligung.
    </p>

    <h4 class="mt-3">d) Nutzung von Event und Ticketmanagement</h4>

    <p>
      Auf unserer Webseite bieten wir Ihnen die Möglichkeit, die Teilnahme an Events zu buchen. Hierzu verwenden wir die
      technische Lösung „Eventbrite“, welche von der Eventbrite, Inc., 155 5th Street, Floor 7, San Francisco, CA 94103,
      USA betrieben wird.
    </p>
    <p>
      Eventbrite hat eine Vertretung zum Zwecke der europäischen Datenschutzgesetzgebung. Dies ist die Eventbrite NL BV
      mit Sitz in Silodam 402, 1013AW, Amsterdam, Niederlande.
    </p>
    <p>Wenn Sie sich für ein Event anmelden, werden Sie auf die Webseite von Eventbrite weitergeleitet.</p>
    <p>
      Eventbrite erfasst personenbezogenen Daten, wenn Sie solche Informationen freiwillig im Rahmen einer Anmeldung zu
      einer unserer Veranstaltungen bereitstellen übermittelt Eventbrite diese Daten anschließend an uns als
      Veranstalter. Um sich bei Eventbrite für ein Event anzumelden, müssen Sie folgende Daten an Eventbrite Inc.
      übermitteln: Nachname, Vorname, E-Mail-Adresse, Unternehmen, Geschäftsanschrift, Zahlungsinformationen, Ticketart
      Event-ID, IP-Adresse, Merkmale des Zugriffsgerätes und/oder des Browsers
    </p>
    <p>
      Welche Daten Eventbrite bei diesem Webseiten-Aufruf speichert und verarbeitet, legt Eventbrite als
      Verantwortlicher in der eigenen Datenschutzerklärung dar. Die Datenschutzerklärung von Eventbrite finden Sie hier:
      <a
        href="https://www.eventbrite.de/support/articles/de/Troubleshooting/datenschutzrichtlinien-von-eventbrite?lg=de"
        >https://www.eventbrite.de/support/articles/de/Troubleshooting/datenschutzrichtlinien-von-eventbrite?lg=de</a
      >
    </p>
    <p>
      Die Eventbrite Inc. nimmt an dem EU-US-Datenschutzschild-Rahmenprogramm des US-Handelsministeriums und der
      Europäischen Kommission in Bezug auf die Erhebung, Nutzung und Aufbewahrung personenbezogener Daten aus den
      Mitgliedstaaten des Europäischen Wirtschaftsraums teil.
    </p>
    <p>
      Hier erhalten Sie Informationen dazu, welche Daten die Eventbrite Inc. im Rahmen des
      EU-US-Datenschutzschild-Rahmenprogramms erhebt, verarbeitet und nutzt und für welche Zwecke dies geschieht:
      <a
        href="https://www.eventbrite.de/support/articles/de/Troubleshooting/informationen-zum-eu-us-datenschutzschild?lg=de"
        >https://www.eventbrite.de/support/articles/de/Troubleshooting/informationen-zum-eu-us-datenschutzschild?lg=de</a
      >.
    </p>
    <p>
      Des Weiteren finden Sie über den nachfolgenden Link weitere Informationen hinsichtlich der Einhaltung der
      europäischen Datenschutzregelungen durch Eventbrite:
      <a
        href="https://www.eventbrite.de/support/articles/de/Troubleshooting/datenverarbeitungsnachtrag-fuer-veranstalter"
        >https://www.eventbrite.de/support/articles/de/Troubleshooting/datenverarbeitungsnachtrag-fuer-veranstalter</a
      >
    </p>
    <p>
      Weitere Informationen dazu, wie Eventbrite Inc. personenbezogene Daten verwendet, erhalten Sie in der
      Datenschutzrichtlinie von Eventbrite:
      <a
        href="https://www.eventbrite.de/support/articles/de/Troubleshooting/datenschutzrichtlinien-von-eventbrite?lg=de"
        >https://www.eventbrite.de/support/articles/de/Troubleshooting/datenschutzrichtlinien-von-eventbrite?lg=de</a
      >.
    </p>
    <p>
      Als Veranstalter erhalten wir von Eventbrite Zugriff auf die oben aufgeführten Daten der Teilnehmer des gebuchten
      Events. Die Daten verwenden wir für die Zwecke der Vor- und Nachbereitung der jeweils gebuchten Events. Zudem
      erhalten registrierte Teilnehmer vor und nach dem gebuchten Event Informationen zum gebuchten Event und unsere
      Kontaktmöglichkeiten per E-Mail zugesendet.
    </p>
    <p>
      Die Verarbeitung der Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO (Verarbeitung zur Erfüllung eines
      Vertrags).
    </p>
    <p>
      Die Nutzer erhalten nach dem Event Informationen per E-Mail zugesendet und werden von uns für Zwecke des Hinweises
      auf ähnliche Events in der Zukunft kontaktiert. Diese Verarbeitungen sind nach Art. 6 Abs. 1 lit. b) DSGVO
      gerechtfertigt. Sie haben jederzeit die Möglichkeit, der Nutzung dieser Daten für werbliche Zwecke für die Zukunft
      zu wiedersprechen, indem Sie Ihrem Wunsch über die Zugangsdaten auf die Plattform www.eventbrite.de über die Sie
      gebucht haben auch widerrufen. Sollten Sie außerhalb von Eventbrite Veranstaltungen bei uns gebucht haben schicken
      Sie Ihren Widerruf an
      <a href="mailto:datenschutzmanagement@optadata-gruppe.de">datenschutzmanagement&#64;optadata-gruppe.de</a>.
      Darüber hinaus sind wir gesetzlich verpflichtet Daten wie Rechnungen, Verträge und andere buchhalterisch relevante
      Informationen für den gesetzlich vorgegebenen Zeitraum zu speichern. Die für diesen Zweck erfolgenden
      Verarbeitungen erfolgen auf Grundlage von Art. 6 Abs. 1 lt. c) DSGVO i.V.m §147 AO und 257 HGB.
    </p>

    <h2 class="mt-5">3. Weitergabe von Daten</h2>

    <p>
      Eine Übermittlung Ihrer persönlichen Daten an Dritte zu anderen als den im Folgenden aufgeführten Zwecken findet
      nicht statt. Wir geben Ihre persönlichen Daten nur an Dritte weiter, wenn:
    </p>

    <ul class="list-group-list">
      <li>Sie Ihre nach Art. 6 Abs. 1 S. 1 lit. a DSGVO ausdrückliche Einwilligung dazu erteilt haben,</li>
      <li>
        die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. f DSGVO zur Geltendmachung, Ausübung oder Verteidigung von
        Rechtsansprüchen erforderlich ist und kein Grund zur Annahme besteht, dass Sie ein überwiegendes schutzwürdiges
        Interesse an der Nichtweitergabe Ihrer Daten haben,
      </li>
      <li>
        für den Fall, dass für die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. c DSGVO eine gesetzliche Verpflichtung
        besteht, sowie
      </li>
      <li>
        dies gesetzlich zulässig und nach Art. 6 Abs. 1 S. 1 lit. b DSGVO für die Abwicklung von Vertragsverhältnissen
        mit Ihnen erforderlich ist.
      </li>
    </ul>

    <h2 class="mt-5">4. Cookies</h2>

    <p>
      Für den Betrieb der Webseite nutzen wir Cookies. Dies sind Dateien, die ihr Browser bei Besuch unserer Webseite
      auf ihrem Endgerät speichert. Sie dienen der Speicherung von Daten Ihres Besuchs und zur Wiedererkennung wie auch
      zur statistischen Erfassung, Verbesserung und Gewährleistung des Betriebes unserer Webseite.
    </p>
    <p>
      Rechtsgrundlage hierfür ist § 6 Abs. 1 lit f DSGVO. Temporäre Cookies werden nach dem Verlassen der Webseite
      gelöscht. Die meisten Browser akzeptieren Cookies automatisch. Sie können Ihren Browser jedoch so konfigurieren,
      dass keine Cookies auf Ihrem Computer gespeichert werden oder stets ein Hinweis erscheint, bevor ein neuer Cookie
      angelegt wird. Die vollständige Deaktivierung von Cookies kann jedoch dazu führen, dass Sie nicht alle Funktionen
      unserer Website nutzen können.
    </p>

    <h2 class="mt-5">5. Analyse-Tools</h2>

    <h4 class="mt-3">Einsatz der Usercentrics Consent Management Plattform</h4>

    <p>
      Auf unserer Webseite setzen wir die Usercentrics Consent Management Plattform („Usercentrics“) ein, um Sie über
      die Technologien zu informieren, die wir auf unserer Website verwenden, sowie Ihre Einwilligung in die
      Verarbeitung Ihrer personenbezogenen Daten gem. Art. 6 Abs. 1 lit. a durch diese Technologien einzuholen, zu
      verwalten und zu dokumentieren. Dazu nutzt Usercentrics eine Checkbox, die beim erstmaligen Betreten der Webseite
      erscheint. Dies ist gemäß Art. 6 Abs. 1 S. 1 lit. c DSGVO zur Erfüllung unserer rechtlichen Verpflichtung gemäß
      Art. 7 Abs. 1 DSGVO erforderlich, Ihre Einwilligung in die Verarbeitung Ihrer personenbezogenen Daten nachweisen
      zu können, der wir unterliegen. Informationen zu den einzelnen Cookies bzw. Technologien finden Sie direkt bei den
      entsprechenden Schaltflächen in der Checkbox.
    </p>
    <p>
      Der Zustimmungsverwaltungsdienst Usercentrics ist ein Angebot der Usercentrics GmbH, Rosental 4, 80331 München,
      die in unserem Auftrag Ihre Daten verarbeitet.
    </p>
    <p>
      Bei Besuch unserer Webseite speichert der Webserver von Usersentrics ein sogenanntes Server-Logfile, das auch Ihre
      anonymisierte IP-Adresse, Datum und Uhrzeit des Besuchs, Geräte- und Browser-Informationen sowie Informationen zu
      Ihrem Einwilligungsverhalten enthält. Ihre Daten werden nach drei Jahren gelöscht, sofern Sie nicht ausdrücklich
      in eine weitere Nutzung Ihrer Daten gemäß Art. 6 Abs. 1 S. 1 lit. a DSGVO eingewilligt haben oder wir uns eine
      darüberhinausgehende Datenverwendung vorbehalten, die gesetzlich erlaubt ist und über die wir Sie in dieser
      Erklärung informieren.
    </p>
    <p>
      Wir nutzen auf unserer Webseite den Webseiten-Analysedienst für Webseiten von Google Analytics, ein
      Webanalysedienst der Google Inc. (<a href="https://about.google/" target="_blank">about.google</a>) (1600
      Amphitheatre Parkway, Mountain View, CA 94043, USA; im Folgenden „Google“). In diesem Zusammenhang werden
      pseudonymisierte Nutzungsprofile erstellt und Cookies (siehe unter Ziff. 4) verwendet. Die durch den Cookie
      erzeugten Informationen über Ihre Benutzung dieser Website wie:
    </p>

    <ul class="list-group-list">
      <li>Browser-Typ/-Version,</li>
      <li>verwendetes Betriebssystem,</li>
      <li>Cookie-ID,</li>
      <li>Referrer-URL (die zuvor besuchte Seite),</li>
      <li>Hostname des zugreifenden Rechners (IP-Adresse),</li>
      <li>Uhrzeit der Serveranfrage,</li>
    </ul>

    <p>
      werden an einen Server von Google in den USA übertragen und dort gespeichert. Die Informationen werden verwendet,
      um die Nutzung der Website auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere
      mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen zu Zwecken der Marktforschung und
      bedarfsgerechten Gestaltung dieser Internetseiten zu erbringen. Auch werden diese Informationen gegebenenfalls an
      Dritte übertragen, sofern dies gesetzlich vorgeschrieben ist oder soweit Dritte diese Daten im Auftrag
      verarbeiten. Es wird in keinem Fall Ihre IP-Adresse mit anderen Daten von Google zusammengeführt. Die IP-Adressen
      werden anonymisiert, so dass eine Zuordnung nicht möglich ist (IP-Masking).
    </p>

    <p>
      Sie können die Installation der Cookies durch eine entsprechende Einstellung der Browser-Software verhindern; wir
      weisen jedoch darauf hin, dass in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website
      vollumfänglich genutzt werden können.
    </p>

    <p>
      Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen
      Daten (inkl. Ihrer IP-Adresse) sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie ein
      Browser-Add-on herunterladen und installieren (<a
        href="https://tools.google.com/dlpage/gaoptout?hl=de"
        target="_blank"
        >tools.google.com/dlpage/gaoptout?hl=de</a
      >).
    </p>

    <p>
      Weitere Informationen zum Datenschutz im Zusammenhang mit Google Analytics finden Sie etwa in der Google
      Analytics-Hilfe (<a href="https://support.google.com/analytics/answer/6004245?hl=de" target="_blank"
        >support.google.com/analytics/answer/6004245?hl=de</a
      >)
    </p>

    <p>
      Bei Nutzung des Dienstes Google Adwords Conversion Tracking wird, sofern Sie über eine Anzeige bei Google auf
      diese Seite gelangt sind, ein Cookie auf Ihrem Gerät gesetzt. Dies dient der Analyse und statistischen Auswertung
      der Nutzung der Webseite. Das Cookie ist 30 Tage gültig. Eine Identifizierung der Person erfolgt nicht, allerdings
      die Beobachtung, ob der Nutzer über eine Google Werbeanzeige auf die Seite gelangt ist.
    </p>

    <p>
      Diese Nutzung können Sie verhindern, indem Sie in den Einstellungen Ihres Browsers das Setzen entsprechender
      Cookies ablehnen.
    </p>

    <h4 class="mt-3">Google Tag Manager</h4>

    <p>
      Wir verwenden den Dienst “Google Tag Manager”. Der Tag Manager ist ein Tool zur Verwaltung von sog. Tags bzw.
      Stichworten, welche beim Tracking im Online Marketing eingesetzt werden können. Der Tag Manager selber verarbeitet
      dabei keine personenbezogenen Daten, da er rein der Verwaltung von anderen Diensten – bspw. Google Analytics, etc.
      – dient. Weitere Informationen zum Tag Manager finden Sie unter:
      <a href="https://marketingplatform.google.com/intl/de/about/analytics/tag-manager/use-policy/" target="_blank"
        >marketingplatform.google.com/intl/de/about/analytics/tag-manager/use-policy</a
      >
    </p>

    <h4 class="mt-3">Nutzung der SalesViewer®-Technologie</h4>

    <p>
      Auf dieser Webseite werden mit der SalesViewer®-Technologie der SalesViewer® GmbH auf Grundlage berechtigter
      Interessen des Webseitenbetreibers (Art. 6 Abs.1 lit.f DSGVO) Daten zu Marketing-, Marktforschungs- und
      Optimierungszwecken gesammelt und gespeichert.
    </p>
    <p>
      Hierzu wird ein javascript-basierter Code eingesetzt, der zur Erhebung unternehmensbezogener Daten und der
      entsprechenden Nutzung dient. Die mit dieser Technologie erhobenen Daten werden über eine nicht rückrechenbare
      Einwegfunktion (sog. Hashing) verschlüsselt. Die Daten werden unmittelbar pseudonymisiert und nicht dazu benutzt,
      den Besucher dieser Webseite persönlich zu identifizieren.
    </p>
    <p>
      Die im Rahmen von Salesviewer gespeicherten Daten werden gelöscht, sobald sie für ihre Zweckbestimmung nicht mehr
      erforderlich sind und der Löschung keine gesetzlichen Aufbewahrungspflichten entgegenstehen.
    </p>
    <p>
      Der Datenerhebung und -speicherung kann jederzeit mit Wirkung für die Zukunft widersprochen werden, indem Sie
      bitte diesen Link
      <a href="https://www.salesviewer.com/opt-out" target="_blank">https://www.salesviewer.com/opt-out</a>
      anklicken, um die Erfassung durch SalesViewer® innerhalb dieser Webseite zukünftig zu verhindern. Dabei wird ein
      Opt-out-Cookie für diese Webseite auf Ihrem Gerät abgelegt. Löschen Sie Ihre Cookies in diesem Browser, müssen Sie
      diesen Link erneut klicken.
    </p>

    <h2 class="mt-5">6. Plugins und Tools</h2>

    <p>
      Auf Basis des Art. 6 Abs. 1 S. 1 lit f DSGVO werden Plug-Ins, Verlinkungen und Buttons zu Social Media Webseiten
      zum Zwecke der Werbung und Information eingesetzt. Durch den Einbau dieser Elemente werden bei Aufruf unserer
      Seite Daten an die entsprechenden Social Media Anbieter übermittelt, ggf. auch in Drittstaaten. Diese Daten werden
      von den Anbietern gespeichert und verarbeitet; insbesondere kann ein Anbieter, sofern Sie gleichzeitig bei den
      Social Media Anbietern eingeloggt sind, den Besuch unserer Webseite erkennen. Auch wenn Sie Beiträge unserer
      Webseite im Social Networt „Teilen“ oder sonst weitergeben („Like“), wird dies dem Anbieter übermittelt und von
      diesem gespeichert, ggf. auch zu Ihrem Nutzerprofil. Die Sozialen Netzwerke nutzen diese Daten zu geschäftlichen
      Zwecken, Marketing, Werbung und weiteren Zwecken, insbesondere auch zum Anlegen und Erstellen von Profilen über
      Sie und für die Bereitstellung personalisierter Werbung. Zweck und Umfang der Datenerhebung und die weitere
      Verarbeitung und Nutzung der Daten durch Facebook sowie Ihre diesbezüglichen Rechte und Einstellungsmöglichkeiten
      zum Schutz Ihrer Privatsphäre entnehmen Sie bitte den Datenschutzhinweisen von Facebook (<a
        href="https://www.facebook.com/about/privacy/"
        target="_blank"
        >www.facebook.com/about/privacy</a
      >).
    </p>

    <h4 class="mt-3">YouTube mit erweitertem Datenschutz</h4>

    <p>
      Diese Website bindet Videos der YouTube ein. Betreiber der Seiten ist die Google Ireland Limited („Google“),
      Gordon House, Barrow Street, Dublin 4, Irland.
    </p>
    <p></p>
    <p>
      Wir nutzen YouTube im erweiterten Datenschutzmodus. Dieser Modus bewirkt laut YouTube, dass YouTube keine
      Informationen über die Besucher auf dieser Website speichert, bevor diese sich das Video ansehen. Die Weitergabe
      von Daten an YouTube-Partner wird durch den erweiterten Datenschutzmodus hingegen nicht zwingend ausgeschlossen.
      So stellt YouTube – unabhängig davon, ob Sie sich ein Video ansehen – eine Verbindung zum Google
      DoubleClick-Netzwerk her. Sobald Sie ein YouTube-Video auf dieser Website starten, wird eine Verbindung zu den
      Servern von YouTube hergestellt. Dabei wird dem YouTube-Server mitgeteilt, welche unserer Seiten Sie besucht
      haben. Wenn Sie in Ihrem YouTube-Account eingeloggt sind, ermöglichen Sie YouTube, Ihr Surfverhalten direkt Ihrem
      persönlichen Profil zuzuordnen. Dies können Sie verhindern, indem Sie sich aus Ihrem YouTube-Account ausloggen.
    </p>
    <p>
      Des Weiteren kann YouTube nach Starten eines Videos verschiedene Cookies auf Ihrem Endgerät speichern oder
      vergleichbare Wiedererkennungstechnologien (z. B. Device-Fingerprinting) einsetzen. Auf diese Weise kann YouTube
      Informationen über Besucher dieser Website erhalten. Diese Informationen werden u. a. verwendet, um
      Videostatistiken zu erfassen, die Anwenderfreundlichkeit zu verbessern und Betrugsversuchen vorzubeugen.
      Gegebenenfalls können nach dem Start eines YouTube-Videos weitere Datenverarbeitungsvorgänge ausgelöst werden, auf
      die wir keinen Einfluss haben. Die Nutzung von YouTube erfolgt im Interesse einer ansprechenden Darstellung
      unserer Online-Angebote. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar.
      Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von
      Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit widerrufbar. Weitere Informationen über Datenschutz bei
      YouTube finden Sie in deren Datenschutzerklärung unter: policies.google.com/privacy?hl=de.
    </p>

    <h4 class="mt-3">Zendesk Helpdesk</h4>

    <p>
      Wir verwenden Zendesk für die Bereitstellung eines Helpdesk sowie Ticketsystem, um eingehende Anfragen von Nutzern
      und Kunden zu bearbeiten.
    </p>

    <p>Weitere Informationen finden Sie hier:</p>

    <div>
      <a href="https://www.zendesk.de/company/customers-partners/privacy-policy/" target="_blank"
        >Datenschutzbestimmungen des Datenverarbeiters</a
      >
    </div>
    <div>
      <a href="https://www.youronlinechoices.eu/" target="_blank">Opt-Out Link</a>
    </div>
    <div>
      <a href="https://www.zendesk.de/company/agreements-and-terms/cookie-policy/" target="_blank"
        >Cookie-Richtlinie des Verarbeiters</a
      >
    </div>

    <h2 class="mt-5">7. Betroffenenrechte</h2>

    <p>Sie haben das Recht:</p>

    <ul class="list-group-list">
      <li>
        gemäß Art. 15 DSGVO Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten zu verlangen. Insbesondere
        können Sie Auskunft über die Verarbeitungszwecke, die Kategorie der personenbezogenen Daten, die Kategorien von
        Empfängern, gegenüber denen Ihre Daten offengelegt wurden oder werden, die geplante Speicherdauer, das Bestehen
        eines Rechts auf Berichtigung, Löschung, Einschränkung der Verarbeitung oder Widerspruch, das Bestehen eines
        Beschwerderechts, die Herkunft ihrer Daten, sofern diese nicht bei uns erhoben wurden, sowie über das Bestehen
        einer automatisierten Entscheidungsfindung einschließlich Profiling und ggf. aussagekräftigen Informationen zu
        deren Einzelheiten verlangen;
      </li>
      <li>
        gemäß Art. 16 DSGVO unverzüglich die Berichtigung unrichtiger oder Vervollständigung Ihrer bei uns gespeicherten
        personenbezogenen Daten zu verlangen;
      </li>
      <li>
        gemäß Art. 17 DSGVO die Löschung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen, soweit nicht
        die Verarbeitung zur Ausübung des Rechts auf freie Meinungsäußerung und Information, zur Erfüllung einer
        rechtlichen Verpflichtung, aus Gründen des öffentlichen Interesses oder zur Geltendmachung, Ausübung oder
        Verteidigung von Rechtsansprüchen erforderlich ist;
      </li>
      <li>
        gemäß Art. 18 DSGVO die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen, soweit die
        Richtigkeit der Daten von Ihnen bestritten wird, die Verarbeitung unrechtmäßig ist, Sie aber deren Löschung
        ablehnen
      </li>
      <li>
        und wir die Daten nicht mehr benötigen, Sie jedoch diese zur Geltendmachung, Ausübung oder Verteidigung von
        Rechtsansprüchen benötigen oder Sie gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung eingelegt haben;
      </li>
      <li>
        gemäß Art. 20 DSGVO Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten,
        gängigen und maschinenlesebaren Format zu erhalten oder die Übermittlung an einen anderen Verantwortlichen zu
        verlangen;
      </li>
      <li>
        gemäß Art. 7 Abs. 3 DSGVO Ihre einmal erteilte Einwilligung jederzeit gegenüber uns zu widerrufen. Dies hat zur
        Folge, dass wir die Datenverarbeitung, die auf dieser Einwilligung beruhte, für die Zukunft nicht mehr
        fortführen dürfen und
      </li>
      <li>
        gemäß Art. 77 DSGVO sich bei einer Aufsichtsbehörde zu beschweren. In der Regel können Sie sich hierfür an die
        Aufsichtsbehörde Ihres üblichen Aufenthaltsortes oder Arbeitsplatzes oder unseres Kanzleisitzes wenden
      </li>
      <li>
        gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung Ihrer personenbezogenen Daten einzulegen, soweit dafür
        Gründe vorliegen, die sich aus Ihrer besonderen Situation ergeben oder sich der Widerspruch gegen Direktwerbung
        richtet. Im letzteren Fall haben Sie ein generelles Widerspruchsrecht, das ohne Angabe einer besonderen
        Situation von uns umgesetzt wird. Sie haben die Möglichkeit, den Widerspruch telefonisch, per E-Mail, per
        Telefax oder an unsere zu Beginn dieser Datenschutzerklärung aufgeführte Postadresse unserer Kanzlei formlos
        mitzuteilen.
      </li>
    </ul>

    <h2 class="mt-5">8. Datensicherheit</h2>

    <p>
      Zur Gewährung der Datensicherheit erfolgt die Übertragung der Inhalte unserer Webseite verschlüsselt nach dem SSL
      Verfahren gemäß dem Stand der Technik. Zur Sicherung der Daten werden von uns und den beauftragten Dienstleistern,
      mit denen entsprechende vertragliche Vereinbarungen getroffen wurden, geeignete Maßnahmen nach dem Stand der
      Technik, insbesondere zur Beschränkung des Zugangs zu den Daten, zum Schutz gegen Veränderungen und Verlust, und
      zur Vertraulichkeit gemäß dem Stand der Technik eingesetzt.
    </p>

    <h2 class="mt-5">9. Stand und Aktualisierung dieser Datenschutzerklärung</h2>

    <p>
      Diese Datenschutzerklärung hat den Stand vom 14.12.2021. Wir behalten uns vor, die Datenschutzerklärung zu
      gegebener Zeit zu aktualisieren, um den Datenschutz zu verbessern und/oder anzupassen.
    </p>

    <h4 class="mt-3">Transparenzgebote</h4>

    <p>
      Aufgrund einer Forderung aus der am 25. Mai 2018 in Kraft getretenen EU Datenschutz-Grundverordnung (DSGVO) sind
      wir, die opta data Finance GmbH Berthold-Beitz-Boulevard 461, 45141 Essen verpflichtet, Sie nach Artikel 13 der
      DSGVO ausführlich über die Verarbeitung Ihrer personenbezogenen Daten auf unserer Webseite (nachfolgend nur noch
      „Daten“ genannt) aufzuklären. Dieser Verpflichtung kommen wir gerne nach. Nachfolgend erläutern wir, welche Daten
      wir von Ihnen zu welchen Zwecken verarbeiten und welche Rechte Sie diesbezüglich haben.
    </p>

    <h4 class="mt-3">Zwecke der Verarbeitung</h4>

    <p>
      Die Daten, die sie uns freiwillig über unsere Webseite zur Verfügung stellen, verwenden wir für folgende Zwecke:
    </p>

    <ul class="list-group-list">
      <li>nach Artikel 6 Abs. 1 a) der DSGVO auf der Basis des von Ihnen freiwillig ausgefüllten Kontaktformulars</li>
      <ul>
        <li>zur Kontaktaufnahme mit Ihnen</li>
        <li>zur Beantwortung konkreter Anfragen</li>
        <li>bei Anmeldung für unseren Newsletter</li>
        <li>zur Vertragsanbahnung</li>
      </ul>
    </ul>

    <h4 class="mt-3">Kategorien der verarbeiteten Daten</h4>

    <ul class="list-group-list">
      <li>Firmenname</li>
      <li>Unternehmensform</li>
      <li>Kundennummer</li>
      <li>Vorname</li>
      <li>Nachname</li>
      <li>Ansprechpartner</li>
      <li>IK-Nummer</li>
      <li>Umsatzsteuer-ID</li>
      <li>Tätigkeitsbereich</li>
      <li>Straße / Hausnummer</li>
      <li>PLZ</li>
      <li>Ort</li>
      <li>Telefonnummer</li>
      <li>Faxnummer</li>
      <li>Mobilnummer</li>
      <li>E-Mail-Adresse</li>
      <li>Kontodaten</li>
      <li>freier Nachrichtentext</li>
    </ul>

    <h4 class="mt-3">Kategorien von Empfängern</h4>

    <p>
      Eine Übermittlung Ihrer Daten findet ausschließlich an Unternehmen innerhalb der opta data Gruppe (Auflistung
      unter
      <a href="https://www.optadata-gruppe.de/unternehmen/unternehmen-der-gruppe" target="_blank"
        >www.optadata-gruppe.de/unternehmen/unternehmen-der-gruppe</a
      >) statt.
    </p>
  </div>
  <!-- End Content -->
</div>
