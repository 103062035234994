"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.removeSpinner = exports.showSpinner = void 0;
function showSpinner(target) {
  var spinnerHTML = "\n    <style>\n        .web-form-spinner {\n          width: 100%;\n          height: 100%;\n          display: flex;\n          align-items: center;\n          justify-content: center;\n        }\n        .web-form-spinner [role=\"progressbar\"] {\n            width: 40px;\n            height: 40px;\n            display: inline-block;\n            color: #00ADDF;\n            animation: circular-rotate 1.4s linear infinite;\n            transform-origin: 50% 50%;\n        }\n        @keyframes circular-rotate{ to { transform: rotate(360deg); } }\n    </style>\n\n    <div class=\"web-form-spinner\">\n      <div role=\"progressbar\">\n        <svg viewBox=\"22 22 44 44\">\n          <circle\n            stroke=\"currentColor\"\n            cx=\"44\"\n            cy=\"44\"\n            r=\"20.2\"\n            fill=\"none\"\n            stroke-width=\"3.6\"\n            stroke-dasharray=\"80 200\"\n            stroke-dashoffset=\"0\"\n          >\n            <animate\n              attributeName=\"stroke-dasharray\"\n              values=\"1,200; 100, 200; 100, 200\"\n              begin=\"0s\"\n              dur=\"1.4s\"\n              repeatCount=\"indefinite\"\n              keyTimes=\"0;0.5;1\"\n              keySplines=\".42 0 .58 1\"\n            />\n            <animate\n              attributeName=\"stroke-dashoffset\"\n              values=\"0; -15; -125\"\n              begin=\"0s\"\n              dur=\"1.4s\"\n              repeatCount=\"indefinite\"\n              keyTimes=\"0;0.5;1\"\n              keySplines=\".42 0 .58 1\"\n            />\n          </circle>\n        </svg>\n      </div>\n    </div>\n  ";
  target.innerHTML = spinnerHTML;
}
exports.showSpinner = showSpinner;
function removeSpinner(ownerDocument) {
  var targetDocument = ownerDocument;
  var spinner = targetDocument.querySelector(".web-form-spinner");
  if (spinner) {
    spinner.parentNode.removeChild(spinner);
  }
}
exports.removeSpinner = removeSpinner;
