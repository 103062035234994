"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.removeWebFormElement = exports.getWebFormElement = exports.createWebFormElement = void 0;
var createWebFormElement = function (ownerDocument) {
  return ownerDocument.createElement("finapi-webform2-webcomponent");
};
exports.createWebFormElement = createWebFormElement;
var getWebFormElement = function (ownerDocument) {
  return ownerDocument.querySelector("finapi-webform2-webcomponent");
};
exports.getWebFormElement = getWebFormElement;
var removeWebFormElement = function (webFormElement) {
  webFormElement.parentNode.removeChild(webFormElement);
};
exports.removeWebFormElement = removeWebFormElement;
