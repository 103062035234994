<div class="row">
  <div *ngIf="auth.user.lang === 'mcc'" class="col-12 d-xl-none welcome-header-m overview-header-color">
    <img alt="header" class="float-image" src="{{ headerImagePath }}" />
    <img alt="logo" class="logo-image-path" src="{{ logoImagePath }}" />
  </div>
  <div *ngIf="auth.user.lang === 'odfin'" class="col-12 d-xl-none welcome-header-mobile overview-header-color">
    <img alt="header" class="float-image" src="{{ headerImagePath }}" />
    <img alt="logo" class="logo-image-path" src="{{ logoImagePath }}" />
  </div>
  <div class="col-12 p-5">
    <p
      class="text-center text-bold mt-4 overview-color"
      style="font-size: 1.3rem"
      appTranslationModal
      [translate]="'WILLKOMMEN.WILKOMMEN'"
    ></p>
    <h1
      class="text-center overview-color"
      style="font-size: 48px; margin-top: 0px"
      appTranslationModal
      [translate]="'WILLKOMMEN.FIRMA_TITLE'"
    ></h1>

    <p
      class="text-center text-bold mt-4"
      style="color: #505050; font-size: 1.3rem"
      appTranslationModal
      [translate]="'WILLKOMMEN.PICK_TEXT'"
    ></p>
    <br />
    <p>{{ welcomeName }},</p>
    <br />
    <span style="color: #505050; font-size: 1rem" appTranslationModal [translate]="'WILLKOMMEN.MAIN_TEXT'"> </span>
  </div>
</div>
