<div class="row g-0">
  <!-- header -->
  <div class="col-12 header">
    <mat-toolbar class="content-card">
      <mat-toolbar-row style="justify-content: space-between">
        <a routerLink="/">
          <img class="logo-nav" src="{{ logoImagePath }}" alt="logo" />
        </a>
      </mat-toolbar-row>
    </mat-toolbar>
  </div>
  <!-- content -->
  <div class="col-12 content-card-main">
    <router-outlet></router-outlet>
  </div>

  <!-- footer -->
  <div class="col-12">
    <div class="footer content-card padding-x">
      <div class="row g-0 px-3">
        <div class="footer-element-container text-center col-12 col-xl-1 order-1 order-xl-2">
          <a class="footer-link" routerLink="impressum" routerLinkActive="active">
            <span appTranslationModal [translate]="'ALLGEMEIN.FOOTER_LINK1'"></span>
          </a>
        </div>
        <div class="footer-element-container text-center col-12 col-xl-1 order-2 order-xl-3">
          <a class="footer-link" routerLink="datenschutz">
            <span appTranslationModal [translate]="'ALLGEMEIN.FOOTER_LINK2'"></span>
          </a>
        </div>
        <div class="footer-element-container text-center col-12 col-xl-1 order-3 order-xl-4">
          <a class="footer-link" routerLink="disclaimer">
            <span appTranslationModal [translate]="'ALLGEMEIN.FOOTER_LINK3'"></span>
          </a>
        </div>
        <div
          *ngIf="authService.user.lang === 'mcc'"
          class="BB-logo-container text-center col-12 col-xl-8 order-4 order-xl-5"
        >
          <a href="https://bankhausbauer.de/" target="_blank">
            <img class="BB-optadata-logo" src="{{ logoImage }}" alt="logo" />
          </a>
        </div>

        <div *ngIf="authService.user.lang === 'odfin'" class="hidden text-center col-12 col-xl-8 order-4 order-xl-5">
          <a href="https://bankhausbauer.de/" target="_blank">
            <img class="BB-optadata-logo" src="{{ logoImage }}" alt="logo" />
          </a>
        </div>

        <div class="footer-element-container text-center col-12 col-xl-1 order-5 order-xl-5">
          <img class="one-logo" src="assets/img/one-logo.svg" alt="logo" />
        </div>
      </div>

      <p style="font-size: 9px; margin-left: 2rem" appTranslationModal [translate]="'ALLGEMEIN.FOOTER_TEXT'"></p>
    </div>
  </div>
</div>
