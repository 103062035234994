import { User } from 'src/app/models/user';
import { AdminService } from 'src/app/services/admin.service';
import { NotificationService } from 'src/app/services/notification.service';

import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogTitle, MatDialogContent, MatDialogActions } from '@angular/material/dialog';

import { UserAdminComponent } from '../children/user-admin/user-admin.component';
import { TranslationDirective } from '../../component/translata-modal/translation.directive';
import { TranslateModule } from '@ngx-translate/core';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatButton } from '@angular/material/button';

@Component({
    selector: 'app-dialog-pop-up',
    templateUrl: './dialog-pop-up.component.html',
    styleUrls: ['./dialog-pop-up.component.scss'],
    standalone: true,
    imports: [
        MatDialogTitle,
        TranslationDirective,
        TranslateModule,
        CdkScrollable,
        MatDialogContent,
        MatDialogActions,
        MatButton,
    ],
})
export class DialogPopUpComponent {
  private user: User;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UserAdminComponent>,
    public adminService: AdminService,
    public notificationService: NotificationService
  ) {
    this.user = data.user;
  }

  closeDialog(): void {
    this.dialogRef.close(false);
  }

  kundigenBestatigen() {
    this.dialogRef.close(true);
  }
}
