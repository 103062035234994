import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { jwtDecode } from 'jwt-decode';
import { KeycloakService } from 'keycloak-angular';
import { DecodedToken } from 'src/app/models/accessToken.model';
import { environment } from 'src/environments/environment';
import { Status } from './models/user';
import { TokenService } from './services/jwttoken.service';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  enterLandingPage: boolean = false;
  constructor(
    private translate: TranslateService,
    private auth: AuthService,
    private keycloakService: KeycloakService,
    private tokenservice: TokenService
  ) {
    this.keycloakService.getToken().then((token) => {
      if (token) {
        let parsedToken = jwtDecode<DecodedToken>(token);
        let email = parsedToken.email; // TODO TK#354
        //this.tokenservice.getAccesstoken(); // TODO getAccesstoken für Sitzung verlängern
        this.auth.login(email).subscribe({
          next: (user) => {
            if (user) {
              translate.addLangs(['mcc', 'odfin']);
              translate.setDefaultLang('mcc');
              if (user.status === Status.active || user.status === Status.finish) {
                this.enterLandingPage = true;
              } else {
                window.location.href = environment.oneDashboardURL;
              }
            } else {
              this.enterLandingPage = false;
            }
          },
          error: (err) => {
            window.location.href = environment.oneDashboardURL;
          },
        });
      }
    });
  }
}
