import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ErrorPageComponent } from 'src/app/pages/footer-navigation/error-page/error-page.component';
import { KeycloakAuthGuard } from './guards/keycloakAuth.guard';
import { DatenschutzComponent } from './pages/footer-navigation/datenschutz/datenschutz.component';
import { DisclaimerComponent } from './pages/footer-navigation/disclaimer/disclaimer.component';
import { FooterComponent } from './pages/footer-navigation/footer/footer.component';
import { ImpressumComponent } from './pages/footer-navigation/impressum/impressum.component';
import { QuestionnaireComponent } from './pages/questionnaire/questionnaire.component';
import { BlankoDokumenteComponent } from './pages/questionnaire/questions/blanko-dokumente/blanko-dokumente.component';
import { DepotbankWahlComponent } from './pages/questionnaire/questions/depotbank-wahl/depotbank-wahl.component';
import { DokumenteComponent } from './pages/questionnaire/questions/dokumente/dokumente.component';
import { EinsichtComponent } from './pages/questionnaire/questions/einsicht/einsicht.component';
import { IdentifizierungComponent } from './pages/questionnaire/questions/identifizierung/identifizierung.component';
import { OverviewComponent } from './pages/questionnaire/questions/overview/overview.component';
import { ZusammenfassungComponent } from './pages/questionnaire/questions/zusammenfassung/zusammenfassung.component';

// Routes für alle componenten/seiten
const routes: Routes = [
  {
    path: '',
    component: QuestionnaireComponent,
    canActivate: [KeycloakAuthGuard],
    children: [
      {
        path: 'overview',
        component: OverviewComponent,
      },
      {
        path: 'bank-wahl',
        component: DepotbankWahlComponent,
      },
      {
        path: 'dokumente',
        component: DokumenteComponent,
      },
      {
        path: 'zusammenfassung',
        component: ZusammenfassungComponent,
      },
      {
        path: 'blanko-dokumente',
        component: BlankoDokumenteComponent,
      },
      {
        path: 'einsicht',
        component: EinsichtComponent,
      },
      {
        path: 'identifizierung',
        component: IdentifizierungComponent,
      },
    ],
  },
  {
    path: 'info',
    component: FooterComponent,
    canActivate: [KeycloakAuthGuard],
    children: [
      {
        path: 'impressum',
        component: ImpressumComponent,
      },
      {
        path: 'datenschutz',
        component: DatenschutzComponent,
      },
      {
        path: 'disclaimer',
        component: DisclaimerComponent,
      },
      {
        path: 'error-page',
        component: ErrorPageComponent,
      },
      {
        path: 'no-result-page',
        component: ErrorPageComponent,
      },
      {
        path: 'not-accepted',
        component: ErrorPageComponent,
      },
      {
        path: 'identity-error',
        component: ErrorPageComponent,
      },
      {
        path: 'last-identity-error',
        component: ErrorPageComponent,
      },
    ],
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.routes').then((m) => m.ADMIN_ROUTES),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
