import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { jwtDecode } from 'jwt-decode';
import { KeycloakService } from 'keycloak-angular';
import { BehaviorSubject, interval, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  tokenExpirationTime: number;
  countdownSubscription: Subscription;

  private timeLeftSubject = new BehaviorSubject<string>('');
  timeLeft$ = this.timeLeftSubject.asObservable();
  constructor(
    private http: HttpClient,
    private keycloackService: KeycloakService
  ) {}

  async getAccesstoken(): Promise<void> {
    try {
      const response = await this.http.get(`${environment.apiURL}/agree21api/accesstoken`).toPromise();

      if (response && response['access_token']) {
        const accessToken = response['access_token'];
        this.handleToken(accessToken);
      } else {
        console.error('Failed to retrieve access token');
      }
    } catch (error) {
      console.error('Error fetching access token:', error);
    }
  }

  handleToken(token: string) {
    const decodedToken = jwtDecode(token);
    const currentTime = Math.floor(Date.now() / 1000);

    if (decodedToken.exp) {
      const timeLeft = decodedToken.exp - currentTime;

      if (timeLeft > 0) {
        this.tokenExpirationTime = timeLeft;

        this.startCountdown(timeLeft);
      } else {
        this.logoutUser();
      }
    }
  }

  startCountdown(timeLeft: number) {
    if (this.countdownSubscription) {
      this.countdownSubscription.unsubscribe();
    }
    this.countdownSubscription = interval(1000).subscribe(() => {
      if (this.tokenExpirationTime > 0) {
        this.tokenExpirationTime--;
        this.updateTimeDisplay(this.tokenExpirationTime);
      } else {
        this.countdownSubscription.unsubscribe();
        this.logoutUser();
      }
    });
  }

  updateTimeDisplay(timeLeft: number) {
    const minutes = Math.floor(timeLeft / 60);
    const seconds = timeLeft % 60;
    const formattedTime = `${minutes}m ${seconds}s`;

    this.timeLeftSubject.next(formattedTime);
  }

  logoutUser() {
    window.sessionStorage.clear();
    this.keycloackService.logout();
  }

  refreshTokenManually() {
    this.getAccesstoken();
  }
}
