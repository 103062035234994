import { Injectable } from '@angular/core';
import { HttpEvent, HttpRequest, HttpHandler, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { NotificationService } from './notification.service';

/**
 * Error interceptor, um fehler meldung einzeigen.
 */
@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {
  constructor(private notification: NotificationService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      // retry(1),
      catchError((errorResp: HttpErrorResponse) => {
        let errorMessage: string;
        if (errorResp.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `Error: ${errorResp.error.message}`;
        } else {
          // server-side error
          errorMessage = `${errorResp.error.message || errorResp.error.Error || errorResp.message}`;
          // errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;

          // ** avoid error on auth.checkLogin */
          if (!request.url.endsWith('user')) this.notification.showError(errorMessage);
        }

        return throwError(errorResp);
      })
    );
  }
}
