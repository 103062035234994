<!--Success-->
<div *ngIf="identityCheckStatus === 'success'">
  <table mat-table [dataSource]="AccountsList" class="card-container full-width">
    <ng-container matColumnDef="Kontobezeichnung">
      <th mat-header-cell *matHeaderCellDef>Kontobezeichnung</th>
      <td mat-cell *matCellDef="let element">
        <p style="font-size: 20px; font-weight: 600">
          {{ element.accountName }}
        </p>
      </td>
    </ng-container>
    <ng-container matColumnDef="Kontoinhaber">
      <th mat-header-cell *matHeaderCellDef>Kontoinhaber</th>
      <td mat-cell *matCellDef="let element">
        <p>{{ element.accountHolder }}</p>
      </td>
    </ng-container>
    <ng-container matColumnDef="IBAN">
      <th mat-header-cell *matHeaderCellDef>IBAN</th>
      <td mat-cell *matCellDef="let element">
        <p>{{ changeFormat(element.iban) }}</p>
      </td>
    </ng-container>
    <ng-container matColumnDef="Information">
      <th mat-header-cell *matHeaderCellDef>Kontoinformationen</th>
      <td mat-cell *matCellDef="let element">
        <p style="font-size: 20px; font-weight: 600">
          {{ element.accountName }}
        </p>
        <p>{{ element.accountHolder }}</p>
        <p>{{ changeFormat(element.iban) }}</p>
      </td>
    </ng-container>
    <form [formGroup]="incomeSpendingGroup">
      <ng-container matColumnDef="Income-Spending">
        <th mat-header-cell *matHeaderCellDef>Geschäftskonto</th>
        <td mat-cell *matCellDef="let element; let i = index">
          <mat-checkbox
            [formControlName]="element.accountId"
            [attr.data-cy]="'checkbox-konto-' + i"
            (change)="selectAtLeastOne(element.accountId, incomeSpendingGroup, $event)"
          >
          </mat-checkbox>
        </td>
      </ng-container>
    </form>
    <tr mat-header-row *matHeaderRowDef="innerWidth > 1000 ? AccountsListdisplayedColumns : ResponsiveColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: innerWidth > 1000 ? AccountsListdisplayedColumns : ResponsiveColumns"
    ></tr>
  </table>

  <div class="d-flex justify-content-end">
    <ul>
      <li *ngIf="!areCheckboxesChecked">Bitte wählen Sie ein oder maximal zwei Konten aus.</li>
    </ul>
  </div>

  <div class="btn-container d-flex justify-content-between">
    <button
      mat-raised-button
      data-cy="button_angebot_pruefen"
      color="primary"
      (click)="nextStep()"
      [disabled]="!areCheckboxesChecked"
    >
      Angebot prüfen<mat-icon style="font-size: 1.3em">arrow_forward_ios</mat-icon>
    </button>
  </div>
</div>

<!--in progress-->
<div class="card-container text-center" *ngIf="identityCheckStatus === 'inprogress'">
  <app-loader [text]="'FINAPI.BANKACCOUNTS_LOADER_TEXT'" [subText]="'FINAPI.BANKACCOUNTS_LOADER_SUBTEXT'"></app-loader>
</div>

<!--failed-->
<div class="card-container" *ngIf="identityCheckStatus === 'failed'">
  <p>failed</p>
</div>
