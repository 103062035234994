<div class="col-xl-10">
  <h1 class="title" appTranslationModal [translate]="'RECHTLICHES.TITLE'"></h1>
  <br />
</div>
<form class="col-xl-10 card-container" [formGroup]="answerFormGroup">
  <div class="row">
    <div class="col-12">
      <div class="row pb-4" id="Rechtliche Hinweise" *ngIf="!juriPerson">
        <h2 class="text-primary" appTranslationModal [translate]="'RECHTLICHES.RECHTLICHE_TITLE'"></h2>
        <div class="line-wrapper-start">
          <mat-icon style="overflow: inherit; color: black" class="error-icon mt-2">remove</mat-icon>
          <p appTranslationModal [translate]="'RECHTLICHES.RECHTLICHE_TEXT1'"></p>
        </div>
        <div class="line-wrapper-start">
          <mat-icon style="overflow: inherit; color: black" class="error-icon mt-2">remove</mat-icon>
          <p appTranslationModal [translate]="'RECHTLICHES.RECHTLICHE_TEXT2'"></p>
        </div>
        <div class="line-wrapper-start pb-4">
          <mat-icon style="overflow: inherit; color: black" class="error-icon mt-2">remove</mat-icon>
          <p appTranslationModal [translate]="'RECHTLICHES.RECHTLICHE_TEXT3'"></p>
        </div>

        <mat-checkbox formControlName="hinweise" data-cy="natPerson_rechtliche-hinweise">
          <p appTranslationModal [translate]="'RECHTLICHES.RECHTLICHE_CHECKBOX_TEXT'"></p>
        </mat-checkbox>
      </div>

      <div class="row pb-4" id="Rechtliche Hinweise" *ngIf="juriPerson">
        <h2 class="text-primary" appTranslationModal [translate]="'RECHTLICHES.JURRECHTLICHE_TITLE'"></h2>
        <div class="line-wrapper-start">
          <mat-icon style="overflow: inherit; color: black" class="error-icon mt-2">remove</mat-icon>
          <p appTranslationModal [translate]="'RECHTLICHES.JURTEXT1'"></p>
        </div>
        <div class="line-wrapper-start">
          <mat-icon style="overflow: inherit; color: black" class="error-icon mt-2">remove</mat-icon>
          <p appTranslationModal [translate]="'RECHTLICHES.JURTEXT2'"></p>
        </div>
        <div class="line-wrapper-start pb-4">
          <mat-icon style="overflow: inherit; color: black" class="error-icon mt-2">remove</mat-icon>
          <p appTranslationModal [translate]="'RECHTLICHES.JURTEXT3'"></p>
        </div>

        <mat-checkbox formControlName="hinweise" data-cy="jurPerson_rechliche-hinweise">
          <p appTranslationModal [translate]="'RECHTLICHES.JURTEXT4'"></p>
        </mat-checkbox>
      </div>

      <div *ngIf="!juriPerson" class="row pb-4" id="selbstauskunft">
        <h2 class="text-primary" appTranslationModal [translate]="'RECHTLICHES.SELBSTAUSKUNFT_TITLE'"></h2>
        <mat-checkbox formControlName="nurDeSteuerpflicht" data-cy="natPerson_selbstauskunft1">
          <p appTranslationModal [translate]="'RECHTLICHES.SELBSTAUSKUNFT_CHECKBOX1_TEXT'"></p>
        </mat-checkbox>
        <mat-checkbox formControlName="usSteuerpflicht" data-cy="natPerson_selbstauskunft2">
          <p appTranslationModal [translate]="'RECHTLICHES.SELBSTAUSKUNFT_CHECKBOX2_TEXT'"></p>
        </mat-checkbox>
      </div>

      <div *ngIf="juriPerson" class="row pb-4" id="selbstauskunft">
        <h2 class="text-primary" appTranslationModal [translate]="'RECHTLICHES.JURTEXT5'"></h2>
        <div class="line-wrapper-start">
          <mat-icon style="overflow: inherit; color: black" class="error-icon mt-2">remove</mat-icon>
          <p appTranslationModal [translate]="'RECHTLICHES.JURTEXT6'"></p>
        </div>

        <div class="line-wrapper-start">
          <mat-icon style="overflow: inherit; color: black" class="error-icon mt-2">remove</mat-icon>
          <p appTranslationModal [translate]="'RECHTLICHES.JURTEXT7'"></p>
        </div>

        <div class="line-wrapper-start">
          <mat-icon style="overflow: inherit; color: black" class="error-icon mt-2">remove</mat-icon>
          <p appTranslationModal [translate]="'RECHTLICHES.JURTEXT8'"></p>
        </div>

        <div class="line-wrapper-start">
          <mat-icon style="overflow: inherit; color: black" class="error-icon mt-2">remove</mat-icon>
          <p appTranslationModal [translate]="'RECHTLICHES.JURTEXT9'"></p>
        </div>

        <br /><br /><br />

        <mat-checkbox formControlName="nurDeSteuerpflicht" data-cy="jurPerson_selbstauskunft">
          <p appTranslationModal [translate]="'RECHTLICHES.JURTEXT10'"></p>
        </mat-checkbox>
      </div>

      <div *ngIf="!juriPerson" class="row pb-4" id="agbs">
        <h2 class="text-primary" appTranslationModal [translate]="'RECHTLICHES.ALLGEMEINE_TITLE'"></h2>
        <mat-accordion>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title class="text-bold">
                <p appTranslationModal [translate]="'RECHTLICHES.ALLGEMEINE_AGB_TITLE'"></p>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <table mat-table [dataSource]="agbDataSource" class="full-width">
              <ng-container matColumnDef="name">
                <td mat-cell *matCellDef="let element">
                  <h3 style="word-break: break-word">{{ element.name }}</h3>
                </td>
              </ng-container>
              <ng-container matColumnDef="download">
                <td mat-cell class="text-right" *matCellDef="let element">
                  <button mat-button color="primary" (click)="openPdf(element.position)">
                    <mat-icon>search</mat-icon>
                  </button>
                </td>
              </ng-container>

              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
          </mat-expansion-panel>
        </mat-accordion>
        <mat-checkbox formControlName="agbs" data-cy="natPerson_agbs">
          <p appTranslationModal [translate]="'RECHTLICHES.ALLGEMEINE_CHECKBOX_TEXT'"></p>
        </mat-checkbox>
      </div>

      <div *ngIf="!juriPerson" class="row pb-4" id="datenschutzhinweise">
        <h2 class="text-primary" appTranslationModal [translate]="'RECHTLICHES.DATENSCHUTZWEISE_TITLE'"></h2>
        <table mat-table [dataSource]="datenschutzDataSource" class="full-width">
          <ng-container matColumnDef="name">
            <td mat-cell *matCellDef="let element">
              <h3>{{ element.name }}</h3>
            </td>
          </ng-container>
          <ng-container matColumnDef="download">
            <td mat-cell class="text-right" *matCellDef="let element">
              <button mat-button color="primary" (click)="openPdf(element.position)" data-cy="natPerson_search-icon">
                <mat-icon>search</mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <mat-checkbox formControlName="datenschutz" data-cy="natPerson_datenschutz">
          <p appTranslationModal [translate]="'RECHTLICHES.DATENSCHUTZWEISE_CHECKBOX_TEXT'"></p>
        </mat-checkbox>
      </div>

      <div *ngIf="!juriPerson" class="row pb-4" id="pep">
        <h2 class="text-primary" appTranslationModal [translate]="'RECHTLICHES.ERKLAERUNG_TITLE'"></h2>
        <div class="line-wrapper-start pb-4">
          <p appTranslationModal [translate]="'RECHTLICHES.ERKLAERUNG_TEXT'"></p>
        </div>
        <mat-radio-group formControlName="offentlichesAmt" (change)="radioPepValidate()">
          <mat-radio-button value="false" class="pb-4" data-cy="natPerson_pep-radiobutton1">
            <p appTranslationModal [translate]="'RECHTLICHES.ERKLAERUNG_RADIO1_TEXT'"></p>
          </mat-radio-button>
          <div class="row">
            <div class="col-9">
              <mat-radio-button value="true" data-cy="natPerson_pep-radiobutton2">
                <p appTranslationModal [translate]="'RECHTLICHES.ERKLAERUNG_RADIO2_TEXT'"></p>
              </mat-radio-button>
            </div>
            <div class="col-3">
              <mat-form-field floatLabel="always" class="full-width" appearance="outline">
                <input matInput formControlName="offentlichesAmtField" />
              </mat-form-field>
            </div>
          </div>
        </mat-radio-group>
      </div>

      <div *ngIf="!juriPerson" class="row pb-4" id="einlagensicherung">
        <h2 class="text-primary" appTranslationModal [translate]="'RECHTLICHES.HINWEIS_TITLE'"></h2>
        <p appTranslationModal [translate]="'RECHTLICHES.HINWEIS_TEXT'"></p>
        <table mat-table [dataSource]="infoEinlegerDataSource" class="full-width">
          <ng-container matColumnDef="name">
            <td mat-cell *matCellDef="let element">
              <h3>{{ element.name }}</h3>
            </td>
          </ng-container>
          <ng-container matColumnDef="download">
            <td mat-cell class="text-right" *matCellDef="let element">
              <button mat-button color="primary" (click)="openPdf(element.position)" data-cy="natPerson_search-icon">
                <mat-icon>search</mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <mat-checkbox formControlName="einlagensicherung" data-cy="natPerson_einlagensicherung">
          <p appTranslationModal [translate]="'RECHTLICHES.HINWEIS_CHECKBOX_TEXT'"></p>
        </mat-checkbox>
      </div>

      <div *ngIf="juriPerson" class="row pb-4" id="agbs">
        <h2 class="text-primary" appTranslationModal [translate]="'RECHTLICHES.JURTEXT11'"></h2>
        <mat-accordion>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title class="text-bold">
                <p appTranslationModal [translate]="'RECHTLICHES.JURTEXT12'"></p>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <table mat-table [dataSource]="agbDataSource" class="full-width">
              <ng-container matColumnDef="name">
                <td mat-cell *matCellDef="let element">
                  <h3 style="word-break: break-word">{{ element.name }}</h3>
                </td>
              </ng-container>
              <ng-container matColumnDef="download">
                <td mat-cell class="text-right" *matCellDef="let element">
                  <button
                    mat-button
                    color="primary"
                    (click)="openPdf(element.position)"
                    data-cy="jurPerson_search-icon"
                  >
                    <mat-icon>search</mat-icon>
                  </button>
                </td>
              </ng-container>

              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
          </mat-expansion-panel>
        </mat-accordion>
        <mat-checkbox formControlName="agbs" data-cy="jurPerson_agbs">
          <p appTranslationModal [translate]="'RECHTLICHES.JURTEXT13'"></p>
        </mat-checkbox>
      </div>

      <div *ngIf="juriPerson" class="row pb-4" id="datenschutzhinweise">
        <h2 class="text-primary" appTranslationModal [translate]="'RECHTLICHES.JURTEXT14'"></h2>
        <table mat-table [dataSource]="datenschutzDataSource" class="full-width">
          <ng-container matColumnDef="name">
            <td mat-cell *matCellDef="let element">
              <h3>{{ element.name }}</h3>
            </td>
          </ng-container>
          <ng-container matColumnDef="download">
            <td mat-cell class="text-right" *matCellDef="let element">
              <button mat-button color="primary" (click)="openPdf(element.position)" data-cy="jurPerson_search-icon">
                <mat-icon>search</mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <mat-checkbox formControlName="datenschutz" data-cy="jurPerson_datenschutz">
          <p appTranslationModal [translate]="'RECHTLICHES.JURTEXT150'"></p>
        </mat-checkbox>
      </div>

      <div *ngIf="juriPerson" class="row pb-4" id="pep">
        <h2 class="text-primary" appTranslationModal [translate]="'RECHTLICHES.JURTEXT15'"></h2>
        <div class="line-wrapper-start pb-4">
          <p appTranslationModal [translate]="'RECHTLICHES.JURTEXT16'"></p>
        </div>
        <mat-radio-group formControlName="offentlichesAmt" (change)="radioPepValidate()">
          <mat-radio-button value="false" class="pb-4" data-cy="jurPerson_pep-radiobutton1">
            <p appTranslationModal [translate]="'RECHTLICHES.JURTEXT17'"></p>
          </mat-radio-button>
          <div class="row">
            <div class="col-9">
              <mat-radio-button value="true" data-cy="jurPerson_pep-radiobutton2">
                <p appTranslationModal [translate]="'RECHTLICHES.JURTEXT18'"></p>
              </mat-radio-button>
            </div>
            <div class="col-3">
              <mat-form-field floatLabel="always" class="full-width" appearance="outline">
                <input matInput formControlName="offentlichesAmtField" />
              </mat-form-field>
            </div>
          </div>
        </mat-radio-group>
      </div>

      <div *ngIf="juriPerson" class="row pb-4" id="einlagensicherung">
        <h2 class="text-primary" appTranslationModal [translate]="'RECHTLICHES.JURTEXT19'"></h2>
        <p appTranslationModal [translate]="'RECHTLICHES.JURTEXT20'"></p>
        <table mat-table [dataSource]="infoEinlegerDataSource" class="full-width">
          <ng-container matColumnDef="name">
            <td mat-cell *matCellDef="let element">
              <h3>{{ element.name }}</h3>
            </td>
          </ng-container>
          <ng-container matColumnDef="download">
            <td mat-cell class="text-right" *matCellDef="let element">
              <button mat-button color="primary" (click)="openPdf(element.position)" data-cy="jurPerson_search-icon">
                <mat-icon>search</mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <mat-checkbox formControlName="einlagensicherung" data-cy="jurPerson_einlagensicherung">
          <p appTranslationModal [translate]="'RECHTLICHES.JURTEXT21'"></p>
        </mat-checkbox>
      </div>

      <div *ngIf="!juriPerson" class="row pb-4" id="schufaZustimmung">
        <h2 class="text-primary" appTranslationModal [translate]="'RECHTLICHES.SCHUFA_TITLE'"></h2>

        <p appTranslationModal [translate]="'RECHTLICHES.SCHUFA_TEXT'"></p>
        <mat-checkbox formControlName="schufaZustimmung" data-cy="natPerson_schufa-zustimmung">
          <p appTranslationModal [translate]="'RECHTLICHES.SCHUFA_CHECKBOX_TEXT'"></p>
        </mat-checkbox>
      </div>

      <div *ngIf="juriPerson" class="row pb-4" id="schufaZustimmung">
        <h2 class="text-primary" appTranslationModal [translate]="'RECHTLICHES.JURTEXT22'"></h2>

        <p appTranslationModal [translate]="'RECHTLICHES.JURTEXT23'"></p>
        <mat-checkbox formControlName="schufaZustimmung" data-cy="jurPerson_schufa-zustimmung">
          <p appTranslationModal [translate]="'RECHTLICHES.JURTEXT24'"></p>
        </mat-checkbox>
      </div>
    </div>
  </div>
</form>

<div class="col-xl-9 line-wrapper" *ngIf="!answerFormGroup.valid">
  <mat-icon *ngIf="auth.user.lang === 'odfin'" style="overflow: inherit" class="error-icon odfincolor">error</mat-icon>
  <mat-icon *ngIf="auth.user.lang === 'mcc'" class="error-icon" style="overflow: inherit">error</mat-icon>
  <p appTranslationModal [translate]="'RECHTLICHES.CHECKBOX_BESTAETIGUNG_TEXT'"></p>
</div>

<div class="col-xl-9 ps-5">
  <ng-container *ngFor="let control of answerFormGroup.controls | keyvalue">
    <div class="d-flex" *ngIf="!control.value.valid">
      <p style="color: red" appTranslationModal [translate]="getControlTranslationKey(control.key)"></p>
    </div>
  </ng-container>
  <button mat-raised-button color="primary" (click)="adminCheckAll()" *ngIf="isAdmin" class="pt-2">
    {{ 'RECHTLICHES.CHECK_ALL' | translate }}
    <mat-icon>check</mat-icon>
  </button>
</div>
