import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { DatePipe } from '@angular/common';

export class Kundendaten {
  Zeitstempel: String;
  TeilnehmerTyp: String;
  UserRegistrationDTO: {
    Iban: String;
    Kontonummer: String;
    BLZ: String;
    Gemeinschaftskonto: boolean;
    Kontoinhaber1: {
      Adresse: {
        Plz: String;
        Ort: String;
        Strasse: String;
        Hausnummer: String;
      };
      Anrede: String;
      Vorname: String;
      Nachname: String;
      Geburtsdatum: String;
      Arbeitgeber: {
        Name: String;
        ArbeitgeberAnsprechpartner: String;
        ArbeitgeberAdresse: {
          Plz: String;
          Ort: String;
          Strasse: String;
          Hausnummer: String;
        };
      };
      PSD2Daten: PSD2Daten[];
    };
    Kundentyp: String;
    UebertragungstypId: number;
    Umstellungsdatum: String;
  };
}

export class PSD2Daten {
  transactionId: number;
  buchungsdatum: String;
  buchungsbetrag: number;
  verwendungszweck: String;
  kundenIban: String;
  glaeubigerId: String;
  mandatsreferenz: String;
  waehrung: String;
  zahlungspartnerIban: String;
  zahlungspartnerName: String;
}

export class kundendatenTest {
  Zeitstempel: String;
  TeilnehmerTyp: String;
  UserRegistrationDTO: {
    Iban: String;
    Gemeinschaftskonto: boolean;
    Kontoinhaber1: {
      Adresse: {
        Plz: String;
        Ort: String;
        Strasse: String;
        Hausnummer: String;
      };
      Anrede: String;
      Vorname: String;
      Nachname: String;
      Geburtsdatum: String;
      Email: String;
    };
    Kundentyp: String;
    UebertragungstypId: number;
  };
}

@Injectable({
  providedIn: 'root',
})
export class KwsService {
  kundendaten = new Kundendaten();
  psd2Daten: PSD2Daten[] = [];

  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private datePipe: DatePipe
  ) {}

  public generateSignatur(): Observable<any> {
    return this.http.post(`${environment.apiURL}/kwsapi/kontoWechselService`, {
      kundendaten: this.kundendaten,
    });
  }

  public postTranscations(url, signatur): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        kundendaten: JSON.stringify(this.kundendaten),
        signatur: signatur,
      },
    });

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
      withCredentials: true,
    };
    return this.http.post(url, params, httpOptions);
  }

  // public postTranscations(): Observable<any> {
  //   return this.http.post(
  //     `${environment.apiURL}/kwsapi/kontoWechselService`,
  //     {
  //       kundendaten: this.kundendaten,
  //     },
  //     { observe: "response" }
  //   );
  // }

  public async buildKundenDaten() {
    this.buildPsd2Daten(this.auth.user.finapi.transactions, this.auth.user.finapi.selectedAccountList[0].iban);
    let index = this.auth.index;
    const iban = this.auth.user.iban;
    //const iban = this.auth.user.iban;
    const kontonummer = iban.slice(-10);
    const blz = iban.slice(4, 12);
    const strasseHausNr = this.auth.user.natPersonen[index].Adresse.Strasse;
    const hausnummer = this.getStrasseAndHausNr(strasseHausNr).houseNumber;
    const strasse = this.getStrasseAndHausNr(strasseHausNr).street;
    let abwStrasseHausNr;
    let abwHausnummer;
    let abwStrasse;
    let abweichendeCurrentAddress: boolean = this.auth.user.natPersonen[index].Firmenanschrift.Strasse ? true : false;
    if (abweichendeCurrentAddress) {
      abwStrasseHausNr = this.auth.user.natPersonen[index].Firmenanschrift.Strasse;
      abwHausnummer = this.getStrasseAndHausNr(abwStrasseHausNr).houseNumber;
      abwStrasse = this.getStrasseAndHausNr(abwStrasseHausNr).street;
    }
    this.kundendaten = {
      Zeitstempel: this.datePipe.transform(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSZ") || '',
      TeilnehmerTyp: 'Kontoinhaber1',
      UserRegistrationDTO: {
        Iban: iban,
        Kontonummer: kontonummer,
        BLZ: blz,
        Gemeinschaftskonto: false,
        Kontoinhaber1: {
          Adresse: {
            Plz: this.auth.user.natPersonen[index].Adresse.PLZ,
            Ort: this.auth.user.natPersonen[index].Adresse.Ort,
            Strasse: strasse,
            Hausnummer: hausnummer,
          },
          Anrede: this.auth.user.natPersonen[index].Person.Anrede,
          Vorname: this.auth.user.natPersonen[index].Person.Vorname,
          Nachname: this.auth.user.natPersonen[index].Person.Nachname,
          Geburtsdatum: this.auth.user.natPersonen[index].Person.Geburtsdatum,
          Arbeitgeber: {
            Name: this.auth.user.natPersonen[index].Firmenname,
            ArbeitgeberAnsprechpartner:
              this.auth.user.natPersonen[index].Person.Titel + ' ' + this.auth.user.natPersonen[index].Person.Vorname,
            ArbeitgeberAdresse: {
              Plz: abweichendeCurrentAddress
                ? this.auth.user.natPersonen[index].Firmenanschrift.PLZ
                : this.auth.user.natPersonen[index].Adresse.PLZ,
              Ort: abweichendeCurrentAddress
                ? this.auth.user.natPersonen[index].Firmenanschrift.Ort
                : this.auth.user.natPersonen[index].Adresse.Ort,
              Strasse: abweichendeCurrentAddress ? abwStrasse : strasse,
              Hausnummer: abweichendeCurrentAddress ? abwHausnummer : hausnummer,
            },
          },
          PSD2Daten: this.psd2Daten,
        },
        Kundentyp: 'Neukunde',
        UebertragungstypId: 2,
        Umstellungsdatum: this.datePipe.transform(new Date(), 'yyyy-MM-dd') || '',
      },
    };
  }

  public getStrasseAndHausNr(address: String) {
    let splitAddress = address.split(' ');
    let lastElement = splitAddress[splitAddress.length - 1];
    let houseNumber: String, street: String;

    if (/^[0-9]+-[a-zA-Z0-9]+$/.test(lastElement)) {
      houseNumber = lastElement;
      splitAddress.pop();
    } else if (/^[0-9]+$/.test(lastElement)) {
      houseNumber = lastElement;
      splitAddress.pop();
    } else {
      let numberOnly = lastElement.match(/^[0-9]+$/);
      if (numberOnly !== null) {
        houseNumber = numberOnly[0];
        splitAddress.pop();
      } else {
        houseNumber = splitAddress[splitAddress.length - 2] + ' ' + lastElement;
        splitAddress.splice(-2, 2);
      }
    }

    street = splitAddress.join(' ');

    return { houseNumber, street };
  }

  public buildPsd2Daten(transactions, selectedAccountIban: String) {
    transactions.forEach((transaction) => {
      this.psd2Daten.push({
        transactionId: transaction.id,
        buchungsdatum: this.datePipe.transform(transaction.bankBookingDate, 'yyyy-MM-dd') || '',
        buchungsbetrag: transaction.amount.toFixed(2),
        verwendungszweck: transaction.purpose,
        kundenIban: selectedAccountIban,
        glaeubigerId: transaction.counterpartCreditorId ? transaction.counterpartCreditorId : '',
        mandatsreferenz: transaction.counterpartMandateReference ? transaction.counterpartMandateReference : '',
        waehrung: transaction.currency ? transaction.currency : '',
        zahlungspartnerIban: transaction.counterpartIban ? transaction.counterpartIban : '',
        zahlungspartnerName: transaction.counterpartName ? transaction.counterpartName : '',
      });
    });
  }
}
