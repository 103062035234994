<div class="text p-5" *ngIf="langIsMcc">
  <h1>Impressum</h1>
  <h2>Angaben gemäß § 5 TMG:</h2>
  <p>MCC Medical CareCapital GmbH<br />Hohenzollernstr. 47 <br />47799 Krefeld</p>
  <h2>Postadresse:</h2>
  <p>Postfach 10 14 03<br />47714 Krefeld</p>
  <h2>Vertreten durch:</h2>
  <p>Olaf Hagelkruys, Thilo Wiers-Keiser</p>
  <h2>Kontakt:</h2>
  <p>Telefon: +49 2151 616607-0<br />Telefax: +49 2151 61660 - 99<br />E-Mail: info&#64;carecapital.de</p>
  <h2>Datenschutzbeauftragter:</h2>
  <p>
    Joachim Kramer<br />Kramer Datenschutz OHG <br />Elsternweg 24<br />42555 Velbert <br />Tel.: +49 2052 / 9289766:
    <br />Fax: +49 2052 / 9289767 <br />E-Mail info&#64;datenschutz-kramer.de
  </p>
  <h2>Registereintrag:</h2>
  <p>Eintragung im Handelsregister. <br />Registergericht: Amtsgericht Krefeld <br />Registernummer: HRB 14709</p>
  <p>
    Die MCC Medical CareCapital GmbH ist zur Erbringung von Finanzdienstleistungen von der Bundesanstalt für
    Finanzdienstleistungsaufsicht (BaFin), Graurheindorfer Straße 108, 53117 Bonn und zur Erbringung von
    Inkassodienstleistungen durch die Registrierungsbehörde des Oberlandesgerichts Hamm zugelassen.
  </p>
  <h2>Umsatzsteuer:</h2>
  <p>Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz:<br />DE 227 420 712</p>
  <h2>Streitschlichtung</h2>
  <p>
    Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
    teilzunehmen.
  </p>
  <h2>Haftung für Inhalte</h2>
  <p>
    Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen
    verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder
    gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige
    Tätigkeit hinweisen.
  </p>
  <p>
    Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben
    hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten
    Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend
    entfernen.
  </p>
  <h2>Haftung für Links</h2>
  <p>
    Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb
    können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets
    der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der
    Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht
    erkennbar.
  </p>
  <p>
    Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer
    Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend
    entfernen.
  </p>
  <h2>Urheberrecht</h2>
  <p>
    Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht.
    Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes
    bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind
    nur für den privaten, nicht kommerziellen Gebrauch gestattet.
  </p>
  <p>
    Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet.
    Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung
    aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir
    derartige Inhalte umgehend entfernen.
  </p>
  <h2>Design und Konzept</h2>
  <p>
    von ConsileonDX GmbH
    <a href="https://www.consileondx.com/" target="_blank" rel="noopener">www.consileondx.com</a>
  </p>
  <h2>Technische Realisierung, Betrieb, Pflege und Warung</h2>
  <p>
    Consileon Business Consultancy GmbH
    <a href="https://www.consileon.de/" target="_blank" rel="noopener">www.consileon.de</a>
  </p>
  <h2>Bonitätsprüfung und Open Banking</h2>
  <p>
    <a href="https://www.finapi.io/" target="_blank" rel="noopener">www.finapi.de</a>
  </p>
  <h2>Kontowechsel Service</h2>
  <p>
    <a href="https://kontowechsel24.de/" target="_blank" rel="noopener">www.kontowechsel24.de</a>
  </p>
</div>
<div class="text" *ngIf="!langIsMcc">
  <div class="header-container mb-5">
    <h1 class="white ps-5">Impressum</h1>
  </div>

  <div class="ps-5">
    <p>Angaben gemäß § 5 TMG</p>
    <h2 class="mt-5">Herausgeber</h2>
    <p>opta data Finance GmbH</p>

    <h2 class="mt-5">Sitz:</h2>
    <p>
      Berthold-Beitz-Boulevard 461<br />
      45141 Essen
    </p>

    <h2 class="mt-5">Handelsregister:</h2>
    <p>Amtsgericht Essen HRB 1995</p>

    <h2 class="mt-5">Umsatzsteuer-ID:</h2>
    <p>
      Umsatzsteuer-Identifikationsnummer gemäß § 27a Umsatzsteuergesetz:<br />
      DE119652507
    </p>

    <h2 class="mt-5">Vertretungsberechtigte Geschäftsführer:</h2>
    <p>Mark Steinbach, Andreas Fischer</p>

    <h2 class="mt-5">Kontakt:</h2>
    <p>
      Telefon: +49 201 31960<br />
      Telefax: +49 201 3196222<br />
      E-Mail:
      <a href="mailto:service@optadata-gruppe.de">service&#64;optadata-gruppe.de</a>
    </p>

    <h2 class="mt-5">Postanschrift:</h2>
    <p>
      Postfach 12 03 13<br />
      45313 Essen<br />
    </p>

    <h2 class="mt-5">Aufsichtsbehörde:</h2>
    <p>
      Bundesanstalt für Finanzdienstleistungsaufsicht (BaFin)<br />
      Graurheindorfer Straße 108<br />
      53117 Bonn<br />
      www.bafin.de
    </p>

    <h2 class="mt-5">Registrierungsbehörde/Aufsichtsbehörde nach dem Rechtsdienstleistungsgesetz</h2>
    <span class="mb-2 d-inline-block">(§ 10 I S. 1 Nr. 1 RDG)</span>
    <p>
      Oberlandesgericht Hamm<br />
      Hesslerstraße 53,<br />
      59065 Hamm<br />
      www.olg-hamm.nrw.de<br />
      Aktenzeichen: 3712-8.115<br />
      www.rechtsdienstleistungsregister.de
    </p>

    <h2 class="mt-5">Information zur Verbraucherschlichtungsstelle gem. § 36 VSBG</h2>
    <p>Wir nehmen nicht an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teil.</p>

    <h2 class="mt-5">Inhaltlich verantwortlich</h2>
    <p>
      Katja Heymann<br />
      opta data Stiftung &amp; Co. KG<br />
      Berthold-Beitz-Boulevard 514<br />
      45141 Essen
    </p>

    <h2 class="mt-5">Redaktionell verantwortlich</h2>
    <p>
      Katja Heymann<br />
      opta data Stiftung &amp; Co. KG<br />
      Berthold-Beitz-Boulevard 514<br />
      45141 Essen
    </p>

    <h2 class="mt-5">Technische Umsetzung</h2>
    <p class="mb-5">
      opta data digital communication GmbH<br />
      Berthold-Beitz-Boulevard 514<br />
      45141 Essen<br />
      www.odWeb.tv
    </p>
  </div>
</div>
