<div class="p-5" *ngIf="langIsMcc">
  <h1>Disclaimer</h1>

  <h2>Haftung für Inhalte</h2>
  <p>
    Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen
    verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder
    gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige
    Tätigkeit hinweisen.
  </p>
  <p>
    Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben
    hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten
    Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend
    entfernen.
  </p>
  <h2>Haftung für Links</h2>
  <p>
    Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb
    können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets
    der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der
    Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht
    erkennbar.
  </p>
  <p>
    Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer
    Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend
    entfernen.
  </p>
  <h2>Urheberrecht</h2>
  <p>
    Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht.
    Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes
    bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind
    nur für den privaten, nicht kommerziellen Gebrauch gestattet.
  </p>
  <p>
    Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet.
    Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung
    aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir
    derartige Inhalte umgehend entfernen.
  </p>
</div>

<div *ngIf="!langIsMcc">
  <div class="header-container">
    <h1>AGB</h1>
    <p>Allgemeine Geschäftsbedingungen der opta data Finance GmbH</p>
  </div>
  <div class="content">
    <p>
      Hier finden Sie die AGB und die weiteren Geschäftsbedingungen der opta data Finance GmbH im praktischen
      PDF-Format:
    </p>
    <ul>
      <li>
        <div class="list-container">
          <h4>AGB Zusatzleistungen</h4>
          <div class="d-flex justify-content-between">
            <p>Stand: 10.05.2022</p>
            <a target="_blank" href="https://www.optadata.de/dist/downloads/agb/agb-zusatzleistungen.pdf"
              ><mat-icon>search</mat-icon></a
            >
          </div>
        </div>
      </li>
      <li>
        <div class="list-container">
          <h4>AGB Abrechnungsstandard</h4>
          <div class="d-flex justify-content-between">
            <p>Stand: 10.05.2022</p>
            <a target="_blank" href="https://www.optadata.de/dist/downloads/agb/Anlage-A_AGB-Dienstleistung1.2.pdf"
              ><mat-icon>search</mat-icon></a
            >
          </div>
        </div>
      </li>
      <li>
        <div class="list-container">
          <h4>Anlage-A AGB-Online-Konfigurator</h4>
          <div class="d-flex justify-content-between">
            <p>Stand: 20.11.2020</p>
            <a
              target="_blank"
              href="https://www.optadata.de/dist/downloads/agb/Anlage-A_AGB-Online-Konfigurator_20201119.pdf"
              ><mat-icon>search</mat-icon></a
            >
          </div>
        </div>
      </li>
      <li>
        <div class="list-container">
          <h4>AGB Anlage A 1 Zusatzleistungen für Krankentransport / Rettungsdienste</h4>
          <div class="d-flex justify-content-between">
            <p>Stand: 08.12.2020</p>
            <a
              target="_blank"
              href="https://www.optadata.de/dist/downloads/agb/Anlage-A_zur-Dienstleistungsvereinbarung_AktivSchutz.pdf"
              ><mat-icon>search</mat-icon></a
            >
          </div>
        </div>
      </li>
      <li>
        <div class="list-container">
          <h4>AGB Anlage P Zusatzleistungen für Krankentransport / Rettungsdienste</h4>
          <div class="d-flex justify-content-between">
            <p>Stand: 16.12.2020</p>
            <a target="_blank" href="https://www.optadata.de/dist/downloads/agb/Anlage_P_TraRett.pdf"
              ><mat-icon>search</mat-icon></a
            >
          </div>
        </div>
      </li>
      <li>
        <div class="list-container">
          <h4>Anlage A – zur Dienstleistungsvereinbarung Select Liquid</h4>
          <div class="d-flex justify-content-between">
            <p>Stand: Mai 2022</p>
            <a target="_blank" href="https://www.optadata.de/dist/downloads/agb/Anlage-A_Select-Liquid.pdf"
              ><mat-icon>search</mat-icon></a
            >
          </div>
        </div>
      </li>
      <li>
        <div class="list-container">
          <h4>Anlage B – zur Dienstleistungsvereinbarung Select Liquid</h4>
          <div class="d-flex justify-content-between">
            <p>Stand: Mai 2022</p>
            <a target="_blank" href="https://www.optadata.de/dist/downloads/agb/Anlage-B_Select-Liquid.pdf"
              ><mat-icon>search</mat-icon></a
            >
          </div>
        </div>
      </li>
      <li>
        <div class="list-container">
          <h4>Anhang zur Anlage A</h4>
          <div class="d-flex justify-content-between">
            <p>Stand: November 2022</p>
            <a target="_blank" href="https://www.optadata.de/dist/downloads/agb/Anhang-zur-Anlage-A.pdf"
              ><mat-icon>search</mat-icon></a
            >
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>
