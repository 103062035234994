<h1 class="text-center" mat-dialog-title appTranslationModal translate="ADMIN.POP_UP_GEKUNDIGT"></h1>
<div mat-dialog-content class="text-center">
  <p appTranslationModal [translate]="'ADMIN.POP_UP_TEXT'"></p>
</div>
<div mat-dialog-actions class="text-center my-1">
  <button mat-button (click)="closeDialog()">
    <span appTranslationModal translate="ADMIN.POP_UP_NO_THANKS"></span>
  </button>
  <button mat-button (click)="kundigenBestatigen()">
    <span appTranslationModal translate="ADMIN.POP_UP_BEASTATIGEN"></span>
  </button>
</div>
