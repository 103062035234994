import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { NgIf } from '@angular/common';
import { MatIcon } from '@angular/material/icon';

@Component({
    selector: 'app-disclaimer',
    templateUrl: './disclaimer.component.html',
    styleUrls: ['./disclaimer.component.scss'],
    standalone: true,
    imports: [NgIf, MatIcon],
})
export class DisclaimerComponent implements OnInit {
  langIsMcc: boolean;
  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.checkUserLang();
  }

  checkUserLang() {
    this.authService.checkLogin().subscribe(() => {
      if (this.authService.user.lang === 'mcc') {
        this.langIsMcc = true;
      } else {
        this.langIsMcc = false;
      }
    });
  }
}
