import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-datenschutz',
    templateUrl: './datenschutz.component.html',
    styleUrls: ['./datenschutz.component.scss'],
    standalone: true,
    imports: [NgIf],
})
export class DatenschutzComponent implements OnInit {
  langIsMcc: boolean;
  constructor(private authService: AuthService) {}

  ngOnInit(): void {
    this.checkUserLang();
  }

  checkUserLang() {
    this.authService.checkLogin().subscribe(() => {
      if (this.authService.user.lang === 'mcc') {
        this.langIsMcc = true;
      } else {
        this.langIsMcc = false;
      }
    });
  }
}
