import { Component, Input, OnInit } from '@angular/core';
import { TranslationDirective } from '../translata-modal/translation.directive';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss'],
    standalone: true,
    imports: [TranslationDirective, TranslateModule],
})
export class LoaderComponent {
  @Input() text?: string | undefined;
  @Input() subText?: string | undefined;
  constructor() {}
}
