import { Component, EventEmitter, HostListener, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors, ValidatorFn, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BankAcount } from 'src/app/models/user';
import { AdminService } from 'src/app/services/admin.service';
import { AuthService } from 'src/app/services/auth.service';
import { FinapiService } from 'src/app/services/finapi.service';
import { KwsService } from 'src/app/services/kws.service';
import { NgIf } from '@angular/common';
import { MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow } from '@angular/material/table';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { LoaderComponent } from '../../../../../component/loader/loader.component';

@Component({
    selector: 'app-bankacounts',
    templateUrl: './bankacounts.component.html',
    styleUrls: ['./bankacounts.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        MatTable,
        MatColumnDef,
        MatHeaderCellDef,
        MatHeaderCell,
        MatCellDef,
        MatCell,
        FormsModule,
        ReactiveFormsModule,
        MatCheckbox,
        MatHeaderRowDef,
        MatHeaderRow,
        MatRowDef,
        MatRow,
        MatButton,
        MatIcon,
        LoaderComponent,
    ],
})
export class BankacountsComponent implements OnInit, OnDestroy {
  @Output() next = new EventEmitter();

  incomeSpendingGroup = new FormGroup({});
  accounts: [];
  AccountsList = [];
  listSubscription: Subscription;
  selectedAccountsList: BankAcount[] = [];
  innerWidth: number;

  AccountsListdisplayedColumns: string[] = ['Kontobezeichnung', 'Kontoinhaber', 'IBAN', 'Income-Spending'];

  ResponsiveColumns: string[] = ['Information', 'Income-Spending'];

  identityCheckStatus: string;
  areCheckboxesChecked: boolean = false;
  constructor(
    private finapiService: FinapiService,
    private router: Router,
    private kwsservice: KwsService,
    private auth: AuthService,
    private adminService: AdminService
  ) {
    this.innerWidth = window.innerWidth;
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }

  ngOnInit(): void {
    this.identityCheckStatus = 'inprogress';
    this.listSubscription = this.finapiService
      .getAccounts(this.finapiService.PROCESS_TOKEN, this.finapiService.ACCESS_TOKEN, this.finapiService.IMPORT_ID)
      .subscribe({
        next: (list) => {
          if (list.length === 0) {
            this.adminService.updateSubStatus(this.auth.user._id, 'finapiStop').subscribe(() => {});
            this.router.navigateByUrl('/info/no-result-page');
          }
          this.AccountsList = list;
          this.finapiService
            .checkIdentity(this.finapiService.PROCESS_TOKEN, this.finapiService.ACCESS_TOKEN)
            .subscribe({
              next: (result) => {
                if (result.nameEquality == true) {
                  list.forEach((account: any) => {
                    this.incomeSpendingGroup.addControl(account.accountId, new FormControl(null));
                  });
                  this.incomeSpendingGroup.setValidators(this.onlyOneCheckboxValidator);
                  this.incomeSpendingGroup.updateValueAndValidity();
                  this.identityCheckStatus = 'success';
                  window.sessionStorage.setItem('identityCheckStatus', this.identityCheckStatus);
                } else {
                  this.identityCheckStatus = 'failed';
                  this.adminService.updateSubStatus(this.auth.user._id, 'finapiStop').subscribe(() => {});
                  this.router.navigateByUrl('/info/identity-error');
                }
              },
              error: (err) => {
                console.log(err, 'err checkIdentity');
                this.adminService.updateSubStatus(this.auth.user._id, 'finapiStop').subscribe(() => {});
                if (err.error.blockedUser) {
                  this.router.navigateByUrl('/info/last-identity-error');
                } else {
                  this.router.navigateByUrl('/info/error-page');
                }
              },
            });
        },
        error: (err) => {
          this.adminService.updateSubStatus(this.auth.user._id, 'finapiStop').subscribe(() => {});
          this.router.navigateByUrl('/info/error-page');
        },
      });
  }

  ngOnDestroy(): void {
    this.listSubscription.unsubscribe();
  }

  async nextStep() {
    await this.exportSelectedList().then(() => {
      this.auth.user.finapi.selectedAccountList = this.selectedAccountsList;
      this.finapiService.postSelectedAccountList(this.selectedAccountsList).subscribe(() => {
        this.finapiService.transactions(this.finapiService.ACCESS_TOKEN, 1).subscribe({
          next: (transactions) => {
            this.auth.user.finapi.transactions = transactions;
            console.log('nextStep transactions length: ', transactions.length);
            this.next.emit();
          },
          error: (err) => {
            this.adminService.updateSubStatus(this.auth.user._id, 'finapiStop').subscribe(() => {});
            this.router.navigateByUrl('/info/error-page');
          },
        });
      });
    });
  }

  async exportSelectedList() {
    this.finapiService.selectedAccountId = [];
    this.selectedAccountsList = [];
    this.pushSelectedList(this.incomeSpendingGroup.controls);
  }

  pushSelectedList(controls: any) {
    for (let controlName in controls) {
      if (controls[controlName].value === true) {
        const selectedAccount: any = this.AccountsList.find(
          (account: { accountId: any }) => account.accountId === controlName
        );

        if (selectedAccount) {
          this.selectedAccountsList.push(selectedAccount);
          this.finapiService.selectedAccountId.push(selectedAccount.accountId);
        }
      }
    }
  }

  onlyOneCheckboxValidator(): ValidatorFn {
    return (formGroup): ValidationErrors | null => {
      const checkboxValues = Object.values(formGroup.value);
      const checkedCount = checkboxValues.filter((value) => typeof value === 'boolean' && value).length;

      return checkedCount === 1 ? null : { notOneChecked: true };
    };
  }

  changeFormat(iban: string) {
    let newIban = [
      iban.substring(0, 4),
      iban.substring(4, 8),
      iban.substring(8, 12),
      iban.substring(12, 16),
      iban.substring(16, 20),
      iban.substring(20, 22),
    ].join(' ');
    return newIban;
  }

  selectAtLeastOne(accountId: string, form: FormGroup, isChecked: any) {
    const control = form.get(accountId);

    if (control) {
      control.patchValue(isChecked.checked);
      this.areCheckboxesChecked = this.checkIfAnyCheckboxChecked(form);
      this.maximalTwo(form);
    }
  }

  private maximalTwo(form: FormGroup) {
    const checkboxValues = Object.values(form.value);
    const checkedCount = checkboxValues.filter((value) => typeof value === 'boolean' && value).length;

    if (checkedCount > 2) {
      this.areCheckboxesChecked = true;
    }
  }

  private checkIfAnyCheckboxChecked(form: FormGroup): boolean {
    const checkboxValues = Object.values(form.value);
    return checkboxValues.some((value) => typeof value === 'boolean' && value);
  }
}
