"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.unload = exports.load = void 0;
var domUtils_1 = require("./domUtils");
var spinner_1 = require("./spinner");
var LOCALHOST = "localhost";
var LOCAL_MOCKOON_PORT = "3002";
var LOCAL_MOCK_PORT = "3000";
var LOCAL_PORT = "8080";
var FINAPI_DOMAIN = ".finapi.net";
var FINAPI_PUBLIC_DOMAIN = ".finapi.io";
var environments = {
  localMock: "http://" + LOCALHOST + ":" + LOCAL_MOCK_PORT,
  local: "http://" + LOCALHOST + ":" + LOCAL_PORT,
  staging: "https://webform-staging.finapi.io",
  releaseStaging: "https://webform-release-staging.finapi.io",
  liveStaging: "https://webform-live-staging.finapi.io",
  sandbox: "https://webform-sandbox.finapi.io",
  live: "https://webform-live.finapi.io"
};
var isTargetLocal = function (targetUrl) {
  var hostname = new URL(targetUrl).hostname;
  return hostname === LOCALHOST ||
  // the given IP address belongs to a private network (local testing)
  isPrivateIPv4(hostname);
};
var isPrivateIPv4 = function (ip) {
  if (/^(10)\.(.*)\.(.*)\.(.*)$/.test(ip)) {
    // 10.x.x.x
    return true;
  } else if (/^(172)\.(1[6-9]|2\d|3[0-1])\.(.*)\.(.*)$/.test(ip)) {
    // 172.16.x.x - 172.31.255.255
    return true;
  } else {
    // 192.168.x.x
    return /^(192)\.(168)\.(.*)\.(.*)$/.test(ip);
  }
};
var isFinApiDomain = function (targetUrl) {
  var hostname = new URL(targetUrl).hostname;
  return hostname.endsWith(FINAPI_DOMAIN) || hostname.endsWith(FINAPI_PUBLIC_DOMAIN);
};
var validateUrl = function (targetUrl) {
  try {
    new URL(targetUrl);
  } catch (err) {
    throw new Error("Invalid 'targetUrl': " + targetUrl);
  }
};
var resolveServer = function (props, type) {
  var targetUrl = props.targetUrl,
    targetEnvironment = props.targetEnvironment;
  if (targetUrl) {
    validateUrl(targetUrl);
  }
  if (targetUrl && !isTargetLocal(targetUrl) && !isFinApiDomain(targetUrl)) {
    throw new Error("Invalid 'targetUrl': " + targetUrl);
  }
  if (targetUrl) {
    return targetUrl;
  } else if (type === "API" && targetEnvironment === "localMock") {
    return "http://" + LOCALHOST + ":" + LOCAL_MOCKOON_PORT;
  } else {
    return environments[targetEnvironment || "live"];
  }
};
var resolveJsPath = function (props) {
  var assetsPath = props.targetUrl === environments["localMock"] || props.targetEnvironment === "localMock" ? "" : "assets/";
  return assetsPath + "finapi-webform2.js?t=" + Date.now();
};
/**
 * Injects Web Component into the target HTMLElement.  When this function is
 * called for the first time, it will also create a new script tag that will
 * fetch the Web Component source js file.  Before the new Web Component, any
 * previous instance will be first automatically unloaded.
 *
 * @param {HTMLElement} target - a target element into which the Web Form will
 * be appended as a child
 * @param {WebFormProps} properties - Web Form properties
 * @param {WebFormHandlers} handlers - Web Form event handlers
 * @param {Document} ownerDocument - a document into which body a script tag
 * that loads Web Component source is injected. If undefined, the
 * globally-scoped document is selected.
 */
function load(target, properties, handlers, ownerDocument) {
  var targetDocument = ownerDocument || document;
  spinner_1.showSpinner(target);
  var sourcesServer = resolveServer(properties, "SOURCES");
  var apiServer = resolveServer(properties, "API");
  var jsPath = resolveJsPath(properties);
  var isEntryPointLoaded = targetDocument.querySelector("#web-form-source") != null;
  if (!isEntryPointLoaded) {
    var entryPoint = targetDocument.createElement("script");
    entryPoint.src = sourcesServer + "/" + jsPath;
    entryPoint.id = "web-form-source";
    entryPoint.async = false;
    entryPoint.onerror = handlers === null || handlers === void 0 ? void 0 : handlers.onLoadError;
    targetDocument.body.appendChild(entryPoint);
  }
  unload(targetDocument);
  var webForm = domUtils_1.createWebFormElement(targetDocument);
  var propertyPort = webForm.properties || webForm;
  propertyPort.platform = "EMBEDDED";
  propertyPort.customerSupportUrl = properties.customerSupportUrl;
  propertyPort.backToAppUrl = properties.backToAppUrl;
  propertyPort.token = properties.token;
  propertyPort.layoutConfig = properties.layoutConfig;
  propertyPort.targetUrl = apiServer;
  propertyPort.targetEnvironment = properties.targetEnvironment || "live"; // TODO: Can be removed once WebForm and loader are in sync
  propertyPort.language = properties.language;
  webForm.addEventListener("loaded", function () {
    return spinner_1.removeSpinner(targetDocument);
  });
  if (handlers) {
    if (handlers.onComplete) {
      webForm.addEventListener("complete", handlers.onComplete);
    }
    if (handlers.onFail) {
      webForm.addEventListener("fail", handlers.onFail);
    }
    if (handlers.onAbort) {
      webForm.addEventListener("abort", handlers.onAbort);
    }
    if (handlers.onLoaded) {
      webForm.addEventListener("loaded", handlers.onLoaded);
    }
  }
  target.appendChild(webForm);
}
exports.load = load;
/**
 * Removes Web Component from the document.
 *
 * @param {Document} ownerDocument - a document in which the web component was
 * injected
 */
function unload(ownerDocument) {
  var targetDocument = ownerDocument || document;
  var webForm = domUtils_1.getWebFormElement(targetDocument);
  if (webForm) {
    domUtils_1.removeWebFormElement(webForm);
  }
}
exports.unload = unload;
