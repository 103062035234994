import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogClose } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { NgClass } from '@angular/common';
import { TranslationDirective } from '../../translata-modal/translation.directive';
import { TranslateModule } from '@ngx-translate/core';
import { MatButton } from '@angular/material/button';

@Component({
    selector: 'app-angebot-abhlenen-dialog-box',
    templateUrl: './angebot-abhlenen-dialog-box.component.html',
    styleUrls: ['./angebot-abhlenen-dialog-box.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        TranslationDirective,
        TranslateModule,
        MatButton,
        MatDialogClose,
    ],
})
export class AngebotAbhlenenDialogBoxComponent {
  imagelang: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private auth: AuthService
  ) {
    this.imagelang = this.auth.user.lang;
  }
}
